import styled from "styled-components";

const TableEN = () => {
  return (
    <TableWrap>
      <tbody>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Type
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Action Subject to Ban
          </TableCell>
          <TableCell
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Restriction Period
          </TableCell>
        </tr>
        <tr>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            1st
            <br />
            Offense
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            2nd
            <br /> Offense
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            3rd <br />
            Offense
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            4th <br />
            Offense
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="1"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Inappropriate conduct
          </TableCell>
          <TableCell style={{ verticalAlign: "middle", textAlign: "center" }}>
            Repeatedly interfering with other players'
            <br />
            game progress with malicious intent
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            3 days
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            7 days
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            15 days
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            Permanence
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="12"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Actions that
            <br /> interfere with
            <br />
            gameplay
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="4"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Using a profile photo, nickname, etc. to
            <br /> directly or indirectly advertise
          </TableCell>
          <TableCell
            rowSpan="6"
            style={{ verticalAlign: "top", textAlign: "center" }}
          >
            7 days
          </TableCell>
          <TableCell
            rowSpan="6"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            15 days
          </TableCell>
          <TableCell
            rowSpan="6"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Permanence
          </TableCell>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Intentionally repeating the same inquiry,
            <br /> report, or purchase cancellation to interfere
            <br />
            with service operation
          </TableCell>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            Circulating false information or confidential
            <br />
            information for undue gain or to cause harm or
            <br />
            confusion to others
          </TableCell>
          <TableCell
            rowSpan="17"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            15 days
          </TableCell>
          <TableCell
            rowSpan="17"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Permanence
          </TableCell>
        </tr>
        <tr />
        <tr />
        <tr>
          <TableCell
            rowSpan="6"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Fraud and <br />
            identity theft
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Deceiving other users to unfairly gain content
            <br /> such as in-game currency
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Impersonating one of the company's <br />
            employees or operators or assuming a false
            <br /> name or another person's identity
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Theft of personal information
          </TableCell>
        </tr>
        <tr />
        <tr />
        <tr />
        <tr>
          <TableCell
            rowSpan="8"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Exploiting <br />
            system errors
            <br /> or bugs
            <br />
            Trading or
            <br /> inducing the
            <br /> trading of content,
            <br /> including cash <br />
            transactions
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Gameplay exploiting application bugs or
            <br /> system errors or using other programs or the
            <br /> suspicion of such
            <br /> Encouraging/attempting/soliciting exchange or
            <br /> trade of content such as user accounts or <br />
            in-game currency using cash, articles, other
            <br /> game content, etc.
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Using user profile photos, nicknames, etc. to
            <br />
            advertise/encourage/induce/attempt/solicit
            <br /> trade of content such as in-game currency
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Exchange of any type of gift voucher for
            <br /> content such as user accounts or in-game <br />
            currency or the attempt/solicitation/purchase
            <br /> of such an exchange
          </TableCell>
        </tr>
        <tr />
        <tr>
          {" "}
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Attempting to exchange or sell content such
            <br /> as in-game currency that has been
            <br /> created/acquired by exploiting application
            <br /> bugs, errors, or illicit programs
          </TableCell>
          <TableCell
            rowSpan="10"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Permanence
          </TableCell>
        </tr>
        <tr />

        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Hacking or
            <br /> account theft
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Theft or illicit use of another user's account or
            <br /> purchase of content using a stolen payment method
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Use or <br />
            dissemination
            <br /> of illicit
            <br /> programs
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Unauthorized manipulation or illicit use of the
            <br /> application or the transmission or
            <br /> dissemination of such methods to other users
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Illegal actions
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            Criminal behavior or attempted criminal
            <br /> behavior in contravention of relevant laws via
            <br /> the game itself or while playing the game
          </TableCell>
        </tr>
      </tbody>
    </TableWrap>
  );
};

const TableWrap = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
`;

const TableCell = styled.td`
  text-align: left;
  vertical-align: middle;
  border: 1px solid black;
  padding: 8px;
  line-height: 1.2rem;
`;
export default TableEN;
