import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ScrollVideo = () => {
  const videoRef = useRef(true);
  const [isSound, setIsSound] = useState(false);
  // eslint-disable-next-line
  const [isPaused, setIsPaused] = useState(false);
  useEffect(() => {
    const handleOpacity = gsap.timeline();
    handleOpacity.to(".coverBox", { autoAlpha: 1 });

    ScrollTrigger.create({
      animation: handleOpacity,
      trigger: ".videoBox",
      start: "top top",
      end: "+=1000",
      scrub: true,
      pin: true,
      markers: false,
      anticipatePin: 1,
    });
  }, []);

  const clickplay = () => {
    if (videoRef.current.paused === false) {
      setIsPaused(false);
      videoRef.current.pause();
    } else {
      setIsPaused(true);
      videoRef.current.play();
    }
  };

  const clickmute = () => {
    let handleMuted = document.getElementById("triggered-element");
    if (videoRef.current.muted === true) {
      setIsSound(true);
      handleMuted.muted = false;
    } else {
      setIsSound(false);
      handleMuted.muted = true;
    }
  };

  return (
    <Wrapper>
      <CtrlButton>{isSound === true ? <MuteBtn onClick={clickmute} /> : <SoundBtn onClick={clickmute} />}</CtrlButton>
      <Section className="videoBox" onClick={clickplay}>
        <VideoBox>
          <VideoContent
            id="triggered-element"
            ref={videoRef}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          >
            <source src="/assets/DosaSurvivor.mp4" type="video/mp4" />
            {"해당 브라우저 환경에서는 비디오가 지원되지 않습니다"}
          </VideoContent>
        </VideoBox>
        <Cover className="coverBox" />
      </Section>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
`;
const Section = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100vh;
`;
const VideoBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  &[poster] {
    width: 100vw;
    height: 100vh;
  }
`;
const CtrlButton = styled.div`
  z-index: 200;
  position: absolute;
  top: 1.2%;
  left: 1.5%;
  @media screen and (max-width: 820px) {
    top: 1%;
    left: 2.5%;
  }
  @media screen and (max-width: 500px) {
    top: 1%;
    left: 3.5%;
  }
`;
const MuteBtn = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background: url("/assets/images/icon_sound_on.png") no-repeat center;
  background-size: 80%;
`;
const SoundBtn = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background: url("/assets/images/icon_sound_off.png") no-repeat center;
  background-size: 80%;
`;
const VideoContent = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 500px) {
  }
`;
const Cover = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100.5%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.85) url("/assets/images/commom_ico_dosa_logo_ver1.png") no-repeat center;
  background-size: 20rem;
  @media screen and (max-width: 500px) {
    background-size: 13rem;
  }
`;
export default ScrollVideo;
