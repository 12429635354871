import React from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import "../styles/swiper.css";

import "swiper/css";
import "swiper/css/navigation";
import Cards from "./card.json";

import { Navigation } from "swiper/modules";
export default function MobileRoadMap() {
  return (
    <Wrapper>
      <MobileTitle>Road Map</MobileTitle>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {Cards.card.map((item, idx) => (
          <SwiperSlide key={idx}>
            <CardBox>
              <Card className="card">
                <CardTop style={{ color: `${idx > 1 ? "#4ED7F5" : "#92E7BB"}` }}>
                  <PlanDate>{item.date}</PlanDate>
                  <PlanType>{item.type}</PlanType>
                  <CardTitle>{item.title}</CardTitle>
                </CardTop>
                <CardContent>
                  {item.explain
                    .replace(/(?:\r\n|\r|\n)/g, "\r\n")
                    .split("\r\n")
                    .map((item) => (
                      <p key={item} style={{ padding: "0.25rem 0", margin:0 }}>
                        {item}
                      </p>
                    ))}
                </CardContent>
              </Card>
            </CardBox>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
    background-color: #1c1c1c;
    padding: 2rem 0;
  }
`;
const Card = styled.div`
  height: fit-content;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: black;
  font-family: "Pretendard";
  width: 300px;
`;
const CardBox = styled.div`
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  justify-content: center;
`;
const CardTop = styled.div``;
const PlanDate = styled.div`
  font-size: 16px;
  padding: 0.25rem 0;
`;
const PlanType = styled.div`
  font-size: 16px;
`;
const CardTitle = styled.div`
  padding: 1.25rem 0;
  font-size: 24px;
  font-weight: 500;
  @media screen and (max-width: 820px) {
    font-size: 20px;
  }
`;
const CardContent = styled.div`
  color: #fff;
`;
const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 4rem 0;
  font-family: "Pretendard";
  font-size: 2.25rem;
  font-weight: 700;
  color: #fff;
`;
