import styled from "styled-components";
import "../styles/term.css"
const PrivacyPolicyEN = () => {
    return (
        <Wrapper>
            <SelectWrap>
                <SelectBox onChange={(e)=>{
                    let lang = e.target.value
                    window.location.replace(`https://dosasurvivor.com/privacy/${lang}`)
                }}>
                    <SelectLanguage value={"kr"}>한국어</SelectLanguage>
                    <SelectLanguage value={"en"} selected={true} >English</SelectLanguage>
                    <SelectLanguage value={"jp"}>日本語</SelectLanguage>
                </SelectBox>
            </SelectWrap>
            <TextWrap>
                <h1 id="-2024-04-28-"><strong>Privacy Policy</strong></h1>
                <p><strong>Effective Date: 04. 28. 2024</strong></p>
                <br/>
                <p><strong>This privacy policy will describe how Mammoth Play collects and uses the personal information
                    of users and what actions Mammoth Play takes to protect personal information. Users may withdraw
                    consent at any time if they do not want their personal information collected, used, provided, or
                    destroyed. However, if users withdraw, they may be unable to use all or part of the service. Also,
                    the information collected is the minimum information required to provide the service.</strong></p>
                <ol>
                    <li style={{lineHeight: "40px"}}>Collection of Information</li>
                    <li style={{lineHeight: "40px"}}>Use of Information Collected</li>
                    <li style={{lineHeight: "40px"}}>Shared Information</li>
                    <li style={{lineHeight: "40px"}}>Information Security</li>
                    <li style={{lineHeight: "40px"}}>Updating and Deleting Information</li>
                    <li style={{lineHeight: "40px"}}>Cooperation with Regulations and Regulatory Agencies</li>
                    <li style={{lineHeight: "40px"}}>Privacy Policy Applicability & Changes</li>
                    <li style={{lineHeight: "40px"}}>California Privacy Rights</li>
                    <li style={{lineHeight: "40px"}}>Help Center</li>
                </ol>
                <br/>

                <p><strong>1. Collection of Information</strong></p>
                <p>Mammoth Play collects various data to provide better services to users, including basic information
                    such as user’s mobile devices and records of service use and, more specifically, information is
                    collected as follows.</p>
                <p>Information from platform provider. To optimize the service, Mammoth Play receives the user's member
                    numbers (ID or e-mail), and/or social media account, nickname, profile, and friend information used
                    in the platform provider's service from the platform provider (Apple, Google, Facebook or Wemix,
                    etc.) or appstore provider when the user downloads the application and uses the service.</p>
                <p>Information collected when you use the service. Information on service usage methods, including the
                    device used, service usage time, usage records, type and price of content purchased, and event
                    participation may be collected during service use. This includes the following data.</p>

                <br/>

                <p>
                    - Device information: Mobile device model and operating system version, device identifier
                    (universally unique identifier), locale information, mobile network information (excluding cell
                    phone number) and other device data.
                </p>
                <p>
                    - Log information: Time connected to the service, Internet Protocol addresses, and service usage
                    records are automatically collected when the service is used.
                </p>
                <p>
                    - Purchase information: If the user purchases content via in-app purchases through Apple or Google,
                    the content purchase time, content type, price, settlement currency, payment method, and order
                    number are automatically recorded.
                </p>
                <p>
                    - Local storage: Mechanisms such as the application data cache are used to collect data and save it
                    locally on the user's device.
                </p>
                <p>
                    If the user does not register as a member on the platform provider or does not use a social media
                    account for the service but rather uses a guest account to access the service, Mammoth Play cannot
                    identify the user solely through data collected while using the service and can only identify users
                    with an account on the platform provider's service or a social media account.
                </p>

                <p><strong>2. Use of Information Collected</strong></p>
                <p>Mammoth Play uses information provided by the platform provider or collected or recorded while using
                    the service for the purposes of service provision, maintenance, security, updates, and development
                    and provision of new services. Data is also used to provide more relevant customized services
                    (including advertisements) to users.</p>
                <p>Mammoth Play may display on the service the user nicknames, profiles, and friend information provided
                    by the platform provider and users may change the display of this information at any time by using
                    the application&#39;s feature enabling users to change the display on the platform provider and/or
                    social media website.</p>
                <p>If users make service-related inquiries via telephone or email, Mammoth Play may keep records of
                    communication with users to provide answers to those inquiries. Furthermore, Mammoth Play may use
                    user’s emails addresses to provide users with information relevant to service usage. Moreover,
                    Mammoth Play may use information collected (excluding information of EEA user’s) or provided to
                    update the service environment or quality for users, and Mammoth Play automated system may analyze
                    users&#39; records of service usage and/or payments to provide individual users with valuable
                    features such as targeted advertisements.</p>
                <p>If Mammoth Play uses collected or provided user data for purposes other than those declared in the
                    Privacy Policy, it will declare these purposes to users in advance and receive prior consent.</p>
                <p>Data collected or provided by Mammoth Play is processed by Amazon Web Services (AWS region:
                    Frankfurt, Germany and California, USA) with safety measures certified as appropriate in accordance
                    with the EU-US Privacy Shield Framework, and user data may be processed at the other AWS
                    region(Seoul, South Korea) to provide better service or implement the service contract to users.</p>

                <p><strong>3. Shared Information</strong></p>
                <p>Mammoth Play does not share data with companies or people outside Mammoth Play with the following
                    exceptions. This data may be shared in the following cases.</p>
                <p>If the user agrees. Mammoth Play may share data with companies or people other than Mammoth Play with
                    user consent. However, if such data must be shared, prior consent shall be obtained from users.</p>
                <p>When external processing is required. Mammoth Play may entrust data processing to its partners or
                    reliable companies in compliance with physical and technical frameworks that follow internal
                    policies (self-regulatory frameworks). In such cases, Mammoth Play bears all liability for the
                    companies to which data processing is entrusted.</p>
                <p>If required by law. If Mammoth Play deems it necessary to access, use, store, or publish information
                    for the following purposes, Mammoth Play will share information with external companies,
                    organizations, or individuals.</p>
                <p>
                    <p>- If data is requested through legal proceedings, regulations, or relevant laws, or by an
                        investigative or regulatory agency with legal power
                    </p>
                    <p>- If needed to detect, prevent, or resolve fraud, technical, or security problems</p>
                    <p>- If needed to protect users or the public from elements threatening user or public rights,
                        assets, or public safety
                    </p>
                </p>
                <p>Mammoth Play may share anonymous data or data that does not allow users to be identified with its
                    partners. Furthermore, if Mammoth Play is subject to the acquisition, merger, or sale of assets
                    (collectively referred to as &quot;M&amp;A&quot; below), it may transfer data to the company
                    undertaking the M&amp;A while maintaining the confidentiality of the information, and in such cases,
                    it will notify users before user data is transferred to the company undertaking the M&amp;A.</p>

                <p><strong>4. Information Security</strong></p>
                <p>Mammoth Play information security management system works to protect the data in Mammoth Play
                    possession from illegal access, modification, release, or deletion. In particular, Mammoth Play
                    periodically reviews the privacy lifecycle and continuously makes necessary updates by applying the
                    latest technology to collect, store, and use personal data to ensure users can safely access the
                    system. This includes using SSL (secure socket layer) and taking physical and technical protective
                    measures to detect unauthorized access to the service system. Furthermore, access authority is
                    limited to the minimum number of employees necessary according to internal policies (self-regulatory
                    frameworks).</p>

                <p><strong>5. Updating and Deleting Information</strong></p>
                <p>Updating information provided by the platform provider. Users may request updates of their own data
                    from the platform provider at any time while using the service. Users who are nationals of EU member
                    nations may additionally request access, rectification, adaptation or alteration, transfer or
                    movement of their data, and may restrict or oppose processing or profiling (collectively referred to
                    as &quot;processing&quot;, below).</p>
                <p>Mammoth Play has no authority to process data provided to the platform provider by the user.
                    Therefore, if users request the processing of their own data provided to Mammoth Play by the
                    platform provider, Mammoth Play will inform users that it has no authority to process user data and
                    instruct them to request data processing from the platform provider and/or social media provider</p>
                <p>Updating information collected while using the service. Users may request updating or processing of
                    their own data from Mammoth Play while using the service (restricted to nationals of EU member
                    nations) and, unless data must be kept for reasonable business purposes or legal purposes, Mammoth
                    Play will attempt to fulfill this processing request without delay. Furthermore, this requested user
                    data processing will be provided free of charge so long as it does not require excessive effort to
                    comply, and Mammoth Play may request verification of user identity before updating or processing the
                    data.</p>
                <p>However, the user request may be denied if it is unreasonably repeated, if it requires excessive
                    technical effort to comply (e.g. if a new system must be developed or existing practices must be
                    fundamentally changed), if the request impinges upon others&#39; rights or freedoms, or if the
                    request is extremely impractical. In such cases, the user may object or file a civil complaint with
                    the privacy protection agency in the user&#39;s country of residence in accordance with Mammoth Play
                    CS procedure for rejection of processing.</p>
                <p>Information Delete: Users may directly request the company to delete the personal information that
                    was collected.(
                    <LinkText href={"/privacy/en/user-rights"}>
                        Click here for more information
                    </LinkText>
                    )  However, to protect user data from accidental or
                    malicious deletion and for maintenance and management of the service, copies of user data may not be
                    immediately deleted from service servers even after a user deletes his or her data from the service.
                    Furthermore, data may not be deleted from backup systems for a certain period of time to comply with
                    laws in the user&#39;s country of residence and/or the country of Mammoth Play business
                    jurisdiction. In such cases, this data will be deleted without delay once the storage period
                    required by relevant laws has elapsed.</p>

                <p><strong>6. Cooperation with Regulations and Regulatory Agencies</strong></p>
                <p>Mammoth Play periodically reviews compliance with its Privacy Policy in accordance with internal
                    policies (self-regulatory frameworks) and strives to protect user data by applying the latest
                    technology. It complies with several regulatory frameworks including EU and American privacy shield
                    frameworks.</p>
                <p>Should a complaint be raised through Mammoth Play user CS procedure, the user will be contacted and
                    further steps are taken. Users from EU member nations may also request details regarding the
                    execution of the Privacy Policy from the representative listed below. Furthermore, should Mammoth
                    Play be unable to resolve any issue related to processing of user data with the user directly, it
                    will cooperate with the privacy protection authority of the country of jurisdiction to resolve this
                    problem.</p>

                <p><strong>7. Privacy Policy Applicability &amp; Changes</strong></p>
                <p>The Privacy Policy does not apply to other sites (including advertisements) linked to the service or
                    sites that may be included in the service. Furthermore, the Privacy Policy is subject to occasional
                    change. If changes are made to the Privacy Policy, these changes will be announced to users
                    beforehand, and if these changes are significant, they will be announced via email or push
                    notifications etc.</p>

                <p><strong>8. California Privacy Rights</strong></p>
                <p>California Residents’ Rights: If you are a California resident, you may have certain additional
                    privacy rights. California consumers have a right to knowledge, access, and deletion of their
                    personal information under the California Consumer Privacy Act (CCPA). California consumers also
                    have a right to opt out of the sale of their personal information by a business and a right not to
                    be discriminated against for exercising one of their California privacy rights. We do not sell your
                    personal information and do not discriminate in response to privacy rights requests. Visit our Your
                    California Privacy Rights page for&nbsp;
                    <LinkText href={"/privacy/en/california"}>
                        more information
                    </LinkText>
                </p>

                <p><strong>9. Help Center</strong></p>
                <p>Mammoth Play does its utmost to protect and safeguard user data. If you have any questions about our
                    Privacy Policy or use of user data or would like to know more, please contact our Help Center
                    indicated below.</p>
                <p>[Help Center]</p>
                <p>Address: 4F, 26, Dosan-daero 28-gil, Gangnam-gu, Seoul, Republic of Korea</p>
                <p>Email: dosa_help@mammothplay.io</p>


            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;
`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const LinkText = styled.a`
    color: blue;
    font-weight: 900;
    cursor: pointer;
`
const SelectWrap = styled.div`
    width: 60%;
    text-align: end;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectBox = styled.select`
    padding: 0.25rem;
    border: 1px solid black;
`
const SelectLanguage = styled.option``

export default PrivacyPolicyEN