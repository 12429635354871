import styled from "styled-components";
import "../styles/term.css";
const TermsOfServiceJP = () => {
  return (
    <Wrapper>
      <SelectWrap>
        <SelectBox
          onChange={(e) => {
            let lang = e.target.value;
            window.location.replace(`https://dosasurvivor.com/terms/${lang}`);
          }}
        >
          <SelectLanguage value={"kr"}>한국어</SelectLanguage>
          <SelectLanguage value={"en"}>English</SelectLanguage>
          <SelectLanguage value={"jp"} selected={true}>
            日本語
          </SelectLanguage>
        </SelectBox>
      </SelectWrap>
      <TextWrap>
        <h1 id="-">
          <strong>利用規約</strong>
        </h1>
        <p>本利用規約は2024年 5月 9日から有効となります。</p>
        <p>
          <strong>第1条（目的）</strong>
        </p>
        <p>
          本利用規約(以下、「規約」という。)はMammoth Play
          Corporation(以下、「当社」という。)が提供するゲームサービス(以下、「サービス」という。)の利用に関して、当社とサービスを利用するお客様間の権利∙義務およびその他の必要な諸事項を定めることを目的とします。
        </p>
        <p>
          <strong>第2条（用語の定義）</strong>
        </p>
        <p>本規約で使用する用語の定義は以下の用語を基準とします。</p>
        <ol>
          <li>
            <p>
              「お客様」とは、本規約に同意し当社とサービス利用契約を締結した利用者をいいます。
            </p>
          </li>
          <li>
            <p>
              「端末」とは、サービスを利用することのできる携帯、スマートフォン、PDA、タブレット、携帯ゲーム機などの有∙無線機器をいいます。
            </p>
          </li>
          <li>
            <p>
              「アプリ(App)」とは、当社が提供するサービスを利用することのできるプログラム一式をいいます。
            </p>
          </li>
          <li>
            <p>
              「アプリストア事業者」とは、当社が提供するアプリをダウンロードし、アプリ内課金による決済を行えるオープンマーケット事業者(Apple
              AppStore、Google Playなど)をいいます。
            </p>
          </li>
          <li>
            <p>
              「プラットフォーム事業者」とは、当社と提携してサービスを提供する事業者と関連サービス(Facebook、LINEなど)をいいます。
            </p>
          </li>
          <li>
            <p>
              「利用者アカウント」とは、アプリストア事業者またはプラットフォーム事業者が提供する、サービスで使用するお客様のアカウントまたは当社がお客様へ付与した記号、文字または特殊文字等の組み合わせたアカウントをいいます。
            </p>
          </li>
          <li>
            <p>
              「ゲストアカウント(Guest
              ID)」とは、当社が利用者アカウントとは別に、お客様の識別とサービスを利用する上で一時的に付与した利用者識別番号を言います。
            </p>
          </li>
          <li>
            <p>
              「コンテンツ」とは、当社がお客様がサービスで利用できるよう制作し、お客様へ提供するアイテム等をいいます。
            </p>
          </li>
          <li>
            <p>
              「アプリ内課金」とは、アプリ内でコンテンツを購入するために決済を行う行為をいいます。
            </p>
          </li>
        </ol>
        <p>
          <strong>第3条（規約の変更）</strong>
        </p>
        <p>
          当社は、当社が必要と判断する場合、サービス提供に必要な範囲内で規約を変更することができます。この場合、当社は、規約変更前に変更される規約の内容及び効力発生日等をお客様が認識できるよう、当社が定めた方法で告知します。変更後の規約は、効力発生日から効力が生じるものとします。
        </p>
        <p>
          <strong>第4条（利用契約）</strong>
        </p>
        <p>
          ①
          サービス利用契約は、アプリをインストール及び起動し、本規約に同意した上でサービス利用の申込みを行い、当社が当該申込みを受信・承諾することで成立します。サービス利用の申込み完了後、端末でアプリが正常に起動された場合、サービス利用が承諾されたものとします。
        </p>
        <p>
          ②
          お客様は、サービスをご利用中に他人の個人情報を盗用した場合、本規約に基づくお客様の権利を主張することができません。この時当社は、利用契約を解除または終了することができます。また、お客様がプラットフォーム事業者にお客様情報を提供し、プラットフォーム事業者を介してサービスを利用する場合でも同一のものとみなします。
        </p>
        <p>
          ③
          当社は、次の各号のいずれかに該当する利用の申込みに対して、承諾しない場合があります。また、利用契約成立後各号の事由があると判断した場合、サービスの全部または一部の提供を中断することができます。この場合、当社はその理由を説明・公開しない場合があります。
        </p>
        <ol>
          <li>
            <p>
              サービス運営ポリシーに基づき、利用制限または永久停止を受けたお客様が利用申請を行う場合
            </p>
          </li>
          <li>
            <p>
              当社が締結した契約に基づき、または特定国からアクセスするお客様へサービス提供を制限する必要性がある場合
            </p>
          </li>
          <li>
            <p>公序良俗その他の関連法令に違反すると判断される場合</p>
          </li>
          <li>
            <p>
              その他第1号から第3号に準ずる事由として承諾が不適切であると判断される場合
            </p>
          </li>
        </ol>
        <p>
          ④
          当社は、第2項及び第3項により、発生するお客様の不利益または損害について一切の責任を負いません。
        </p>
        <p>
          <strong>第5条（アカウント管理）</strong>
        </p>
        <p>
          お客様は自身の利用者アカウント(ゲストアカウントを含む)を誠実に注意義務を尽くし管理しなければなりません。当社はお客様が自身の利用者アカウントををないがしろに管理したり第三者に利用を承諾することで発生する損害に対して、一切の責任を負いません。
        </p>
        <p>
          <strong>第6条（プライバシー）</strong>
        </p>
        <p>
          ①
          当社は、利用者アカウントを含むお客様の個人情報を保護するために最善を尽くします。お客様のプライバシー及び取り扱いは当社が別途定めるプライバシーポリシーに従います。
        </p>
        <p>
          ②
          当社のサービスにリンクされた第三者のサービスまたは広告については、当社のプライバシーポリシーが適用されません。
        </p>
        <p>
          ③
          当社は、お客様の選択や帰責事由により、他人(他のお客様を含む)に提供されたり露出されたお客様の個人情報(利用者アカウントを含む)に対して一切の責任を負いません。
        </p>
        <p>
          <strong>第7条（会社の義務）</strong>
        </p>
        <p>① 当社は、本規約で定める権利の行使と義務を誠実に履行します。</p>
        <p>
          ②
          当社は、お客様から提出された意見や不満が正当で客観的であると判断される場合、合理的な期間内に処理します。ただし、処理に長期間を要する場合には、お客様にその事由と処理日程を別途通知または案内します。
        </p>
        <p>
          ③ 当社はサービスに不具合が発生した場合、
          やむを得ない事由がない限り、遅滞なく、これを修理または復旧するために最善の努力を尽くします。
        </p>
        <p>
          <strong>第8条（お客様の義務）</strong>
        </p>
        <p>
          ①
          お客様は、当社が提供するサービスを、サービス本来の利用目的以外の用途で利用したり、次の各号のいずれかに該当する行為を行ってはなりません。
        </p>
        <ol>
          <li>
            <p>
              お問い合わせ、コンテンツの復旧、イベントの当選等、当社に個人情報提供時他人の個人情報を使用したり虚偽の事実を記載する行為
            </p>
          </li>
          <li>
            <p>
              他のお客様の利用アカウントを盗用または不正に使用したり、他人の決済手段ないし情報を盗用して、コンテンツを購入する行為
            </p>
          </li>
          <li>
            <p>
              コンテンツを他人と売買(贈与を含む)、もしくは、これらを取得して利用する行為
            </p>
          </li>
          <li>
            <p>
              当社のサービスまたはアプリを利用して取得した情報を商業/非商業的に利用したり、未知の不具合等を活用してサービスを利用する行為
            </p>
          </li>
          <li>
            <p>
              当社のサービスまたはアプリを利用して自身または他人に財産上の利益を発生させる行為
            </p>
          </li>
          <li>
            <p>他人の名誉を毀損したり、損害を加える行為</p>
          </li>
          <li>
            <p>
              当社または第三者の知的財産権、肖像権等その他の権利を侵害する行為
            </p>
          </li>
          <li>
            <p>
              第三者を欺罔して利益を得たり、または当社が提供するサービスを不健全に利用したり、それを利用して第三者に被害を与える行為
            </p>
          </li>
          <li>
            <p>
              淫乱・低俗な情報を交流・掲載、または、淫乱サイトをリンクして繋げたり、承認されていない広告または宣伝物を掲載・送信または流布等を行う行為
            </p>
          </li>
          <li>
            <p>
              富をかけてギャンブルするなど蛇行行為を誘導したり、参加する行為
            </p>
          </li>
          <li>
            <p>
              羞恥心、嫌悪感、不快または恐怖心などを引き起こす文字・記号・音響・映像などを掲載したり、他の利用者に送信・流布等を行う行為
            </p>
          </li>
          <li>
            <p>
              データ、プログラム、アプリなどを滅失、毀損、変造、偽造などをしたり、その運用を妨害する行為
            </p>
          </li>
          <li>
            <p>
              アプリを任意に変更またはアプリに他のプログラムを追加∙挿入したり、サーバーとアプリ間のデータ通信を操作・変更等を行う行為
            </p>
          </li>
          <li>
            <p>
              当社の同意なしに営利、営業、広告、政治活動、選挙運動、宗教活動や宗教団体勧誘、ストーカー行為などを目的にサービスを使用すること行為
            </p>
          </li>
          <li>
            <p>
              公共の秩序及び公序良俗に違反する違法、不当な行為と関連法令に違反する行為
            </p>
          </li>
          <li>
            <p>
              その他、サービス運営を妨害する行為、またはサービスの運営に関連して、会社が不適当であると合理的に判断する行為
            </p>
          </li>
        </ol>
        <p>
          ②
          お客様は、当社が通知する事項及び本規約の改定事項等を随時確認し、遵守する義務があり、その他当社の業務を妨害する行為を行ってはなりません。
        </p>
        <p>
          ③
          お客様は、利用者アカウント及びサービスを利用する端末に対する管理責任を負担し、他人にこれを利用できるようしてはなりません。当社はお客様の
          過失ないし管理不十分または端末利用許諾などを介して発生する利用者の不利益ないし損害について一切の責任を負いません。
        </p>
        <p>
          ④
          当社はサービス運営ポリシーを運営し、お客様は当社で定めたサービス運営ポリシーを遵守しなければなりません。
        </p>
        <p>
          <strong>第9条（サービスの利用及び中断等）</strong>
        </p>
        <p>
          ①
          当社は、お客様にサービス利用を承諾した時からサービスを開始します。但し、一部サービスの場合、当社の必要に応じて指定日からサービスを提供します。
        </p>
        <p>
          ② お客様ががゲストアカウント(Guest
          ID)を使用してサービスを利用する場合、使用中の端末からアプリを削除したり機種変更を行うことでコンテンツのサービス利用履歴が削除される恐れがあるため、利用者アカウントを使用してサービスを利用することをお勧めします。当社は、お客様がゲストアカウント(Guest
          ID)でサービスを利用する場合、最初のサービス利用時にこれら内容を事前に通知し、アプリ削除または機種変更等により発生するお客様の損害に対して、一切の責任を負いません。
        </p>
        <p>
          ③
          ネットワークを通じてアプリをダウンロード(アップデートを含む)したりサービスを利用する場合には、ご利用中の電気通信事業者で定めた別途料金が発生する場合があります。また、機種変更、海外ローミングなどの場合には、コンテンツの全て、または一部の利用に制限が発生する場合があり、ダウンロードしてインストールしたアプリまたはネットワークを通じて利用するサービスの場合には、バックグラウンド作業が行われる場合があります。この場合、端末または電気通信事業者の特性に応じて追加料金が発生する恐れがあり、当社はこれらに関する一切の責任を負いません。
        </p>
        <p>
          ④
          当社は、システムのメンテナンス、サーバーの増設及び変更、各種不具合パッチ、サービス変更など、運用上必要な場合、一定期間サービスを中断することができます。この場合、当社はその内容及び時間を事前に告知します。ただし、当社が事前に告知することのできないやむを得ない事由がある場合には、事後告知を行います。
        </p>
        <p>
          ⑤
          当社は、営業譲渡、分割∙合併などによるサービスの廃止、サービス提供契約終了、当該サービスの収益悪化など、経営上の重大な事由によりサービスを継続することが困難な場合には、すべてのサービスを中断することができます。
        </p>
        <p>
          <strong>第10条（サービス内容及び変更）</strong>
        </p>
        <p>
          ①
          お客様は、本規約及びサービス運営ポリシーに基づきサービスを利用しなければなりません。
        </p>
        <p>
          ②
          当社は、サービスの運営・変更・終了など、サービスに関連する包括的な権限を持ちます。
        </p>
        <p>
          ③
          当社は、次の各号いずれかに当てはまる場合、サービスの全部または一部を制限・停止または終了することができます。
        </p>
        <ol>
          <li>
            <p>
              戦時、事変、天災地変または国家非常事態などの不可抗力的な事由がある場合
            </p>
          </li>
          <li>
            <p>
              停電、諸般の設備の障害又は利用量の暴走などで正常なサービス利用に支障がある場合
            </p>
          </li>
          <li>
            <p>サービス設備の補修など工事によるやむを得ない場合</p>
          </li>
          <li>
            <p>他社の諸事情でサービスを提供することができない場合</p>
          </li>
        </ol>
        <p>
          ④
          当社は、サービスが変更されたり停止した原因が当社の故意または重過失による場合を除いて、サービスの変更または停止により発生する問題に対する一切の責任を負いません。
        </p>
        <p>
          <strong>第11条（情報の提供及び広告の掲載）</strong>
        </p>
        <p>
          ①
          当社は、プラットフォーム事業者またはアプリストア事業者を通じて提供された利用者情報以外にも、お客様に追加情報を要求することができ、収集または提供された利用者情報についてはプライバシーポリシーに従います。
        </p>
        <p>
          ②
          当社はサービスに広告を掲載することができ、お客様はサービス利用時広告が表示・露出されることに同意します。
        </p>
        <p>
          ③
          当社は、お客様が第2項の広告に接続・参加・取引などを行うことにより発生する損失または損害をに対して、一切の責任を負いません。
        </p>
        <p>
          <strong>第12条（アプリ内決済）</strong>
        </p>
        <p>
          ①
          お客様は、端末のパスワード設定機能、アプリストア事業者やプラットフォーム事業者等（以下、「決済事業者」という。）が提供する機能などを利用して、他人によるアプリ内決済を防止しなければなりません。
        </p>
        <p>
          ②
          当社は、お客様がアプリ内決済防止機能等を使用しない場合や、お客様の不注意によりパスワードが露出し発生する他人によるアプリ内決済に対して、一切の責任を負いません。
        </p>
        <p>
          ③
          お客様が端末でアプリ内決済を行った場合、その内容は法定代理人の同意があるものとみなします。
        </p>
        <p>
          ④
          当社の方針と決済事業者のポリシーなどによって各決済手段ごとに決済限度が付与または調整する場合があります。
        </p>
        <p>
          ⑤
          お客様は、サービス利用中の端末の種類に応じて、決済事業者のポリシーに基づきコンテンツを購入することができ、決済ポリシーの違いにより決済金額に差が生じる場合があります。また、コンテンツの決済金額は決済事業者が定めた方法及びポリシーに基づき支払方法も決済事業者のポリシーに従います。
        </p>
        <p>
          ⑥
          お客様が購入したコンテンツは、アプリをダウンロードしてインストールした端末でのみ使用することができ、コンテンツを使用する場合、有料コンテンツを優先で消費し、その後無料コンテンツが消費されます。
        </p>
        <p>
          ⑦
          お客様は、コンテンツを利用者アカウントでのみ利用することができ、他人に譲渡・貸与・売買または交換することができません。
        </p>
        <p>
          <strong>第13条（アプリ内課金決済キャンセルなど)</strong>
        </p>
        <p>
          ①
          お客様が購入したコンテンツに対する払い戻しは、お客様が利用する決済事業者のポリシーに基づき行われ、詳しい申請手続きは決済事業者のポリシーに従います。また、購入したコンテンツに対する払い戻しが完了した場合、返金に当てはまるコンテンツは回収・差し引かれます。
        </p>
        <p>
          ②
          お客様がサービス利用行為で取得したり、他人からプレゼントされたコンテンツ、当社のイベント等を通じて取得したコンテンツは返金対象ではありません。
        </p>
        <p>
          <strong>第14条（契約解除及びサービス利用中止など)</strong>
        </p>
        <p>
          ①
          お客様はいつでもサービスの利用を望まない場合、アプリ内の脱退・アカウント削除を使って利用契約を解除することができます。
        </p>
        <p>
          ②
          当社は、サービス利用契約が解除(脱退・アカウント削除)された場合、サービス利用履歴、コンテンツ等、すべてを削除します。
        </p>
        <p>
          ③
          当社は、お客様が第8条またはサービス運営ポリシーで定めた事項を違反する場合、利用契約を解除したり、または一定期間サービス利用を制限することができます。
        </p>
        <p>
          ④
          当社は、第3項の規定によるサービス利用制限が正当な場合、サービスの利用制限により発生するお客様の不利益や損害について一切の責任を負いません。
        </p>
        <p>
          ⑤
          当社は、お客様がサービスを２年（最後にサービスを利用した日から２年）以上利用していない場合、サービス利用契約が解除されたものとみなし、利用者アカウントを削除することができます。この場合、当社はお客様に発生する不利益や損害について一切の責任を負いません。
        </p>
        <p>
          <strong>第15条（暫定措置としての利用制限）</strong>
        </p>
        <p>
          当社は次の各号のいずれかに該当する問題に対する調査が完了するまで、お客様のサービス利用を暫定的に中止することができます。
        </p>
        <ol>
          <li>
            <p>
              利用者アカウントがハッキングまたは盗用されたという正当な申告が受理された場合
            </p>
          </li>
          <li>
            <p>
              サービス運営ポリシーで定める制裁対象行為に該当すると合理的に疑われる場合
            </p>
          </li>
          <li>
            <p>その他第1号及び第2号に準ずる行為に該当すると判断される場合</p>
          </li>
        </ol>
        <p>
          <strong>第16条（損害賠償及びその制限）</strong>
        </p>
        <p>
          ①
          お客様は、本規約の義務を違反することにより当社に損害を与えた場合、またはサービスを利用する中で当社に損害を与えた場合、当社に対してその損害を賠償しなければなりません。
        </p>
        <p>
          ②
          当社の損害賠償責任は、請求の原因を問わず、お客様に現実的に発生した直接的な損害に限り、間接損害、特別損害、付随的損害（利用機会の喪失、データの損失を含むが、これに限らない。）については、一切の責任を負いません。ただし、会社の故意または重過失の場合にはその限りではありません。
        </p>
        <p>
          ③
          第2項の規定による当社の損害賠償責任は、その原因が発生した日から遡及して3ヶ月の間にお客様が購入したコンテンツ決済金額を上限とします。
        </p>
        <p>
          <strong>第17条（免責）</strong>
        </p>
        <p>
          ①
          当社は天災地変、国家非常事態、解決が困難な技術的欠陥その他の不可抗力的な事由でサービスを提供することができない場合には、その責任が免除されます。
        </p>
        <p>
          ②
          当社はお客様の責に帰すべき事由によるサービスの停止∙利用障害などに対して責任を負わず、電気通信事業者が電気通信サービスを中止したり、正常に提供することができずお客様に損害が発生した場合にも責任を負いません.
        </p>
        <p>
          ③
          当社は、事前に告知されたり、緊急に実施されたサービス設備の補修、交換、定期メンテナンスなどのやむを得ない事由によりサービスが停止したり、障害が発生した場合には、その責任が免除されます。
        </p>
        <p>
          ④
          当社はお客様がサービスを利用して期待する点数（スコア）、順位（ランキング）などを得られなかったことに対して責任を負わず、サービスの折衷や利用に発生する損害等については責任が免除されます。
        </p>
        <p>
          ⑤
          当社はお客様が本人の個人情報など（利用者アカウントを含む）を変更したり、他人に提供することにより得る不利益等について、一切の責任を負いません。
        </p>
        <p>
          ⑥
          当社はお客様の端末環境、当社の帰責事由のないネットワーク環境などによりサービス利用中に発生する問題について一切の責任を負いません。
        </p>
        <p>
          ⑦
          当社はお客様相互間またはお客様と第三者の間でサービスを媒介に発生した紛争について介入する義務がなく、これによる損害を賠償する責任もありません。
        </p>
        <p>
          <strong>第18条（裁判権及び準拠法）</strong>
        </p>
        <p>
          サービスを利用する上で当社とお客様間で発生する紛争は、大韓民国の法律で定められた手順に従います。
        </p>
        &nbsp;
        <p>効力発生日：2024. 5. 9.</p>
      </TextWrap>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 3rem 0;
  background-color: white;
  color: black;
`;
const TextWrap = styled.div`
  width: 60%;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectWrap = styled.div`
  width: 60%;
  text-align: end;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectBox = styled.select`
  padding: 0.25rem;
  border: 1px solid black;
`;
const SelectLanguage = styled.option``;

export default TermsOfServiceJP;
