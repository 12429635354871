import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Cards from "./card.json";
import SectionTeam from "./SectionTeam";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../styles/scroll.css";
import MobileRoadMap from "./MobileRoadMap";

const SectionRoadMap = () => {
  const cardRef = useRef(null);
  const pageRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -80,
      ease: "none",
      scrollTrigger: {
        trigger: ".container",
        pin: true,
        scrub: 1,
        end: () => "+=" + document.querySelector(".container").offsetWidth,
      },
    });

    gsap.to("progress", {
      value: 100,
      ease: "none",
      scrollTrigger: {
        trigger: ".container",
        scrub: 0.3,
        start: "start 0px",
        end: () => "+=" + document.querySelector(".container").offsetWidth / 2,
      },
    });
  }, []);

  return (
    <Wrapper ref={pageRef}>
      <Section id="page-trig">
        <LastWrapper>
          <EllipseImg src="/assets/images/Ellipse.png" alt="ellipse" />
          <BgImg src="/assets/images/section_01_bg.png" alt="bg-img" />
        </LastWrapper>
      </Section>
      <SectionWrapper id="firstContainer">
        <SectionTeam />
      </SectionWrapper>
      <Container className="container">
        <ShowProgress>
          {/* {Cards.card.map((item, idx) => (
            <ProgressType id="types" key={idx}>
              {curr === 1 ? item.date : ""}
            </ProgressType>
          ))} */}
          <ProgressBar>
            <ProgressRate max="100" value="0" />
          </ProgressBar>
        </ShowProgress>
        <div className="panel">
          <OuterWrapper>
            <MobileTitle>Road Map</MobileTitle>
            <PageTitle className="card">
              <Title>Road Map</Title>
              <ArrowIcon src="/assets/images/common_ico_arrow_right_large.png" />
            </PageTitle>
            {Cards.card.map((item, idx) => (
              <CardBox key={idx}>
                <Card ref={cardRef} className="card">
                  <CardTop style={{ color: `${idx > 1 ? "#4ED7F5" : "#92E7BB"}` }}>
                    <PlanDate>{item.date}</PlanDate>
                    <PlanType>{item.type}</PlanType>
                    <CardTitle>{item.title}</CardTitle>
                  </CardTop>
                  <CardContent>
                    {item.explain
                      .replace(/(?:\r\n|\r|\n)/g, "\r\n")
                      .split("\r\n")
                      .map((item) => (
                        <p key={item} style={{ padding: "0.25rem 0", margin:0 }}>
                          {item}
                        </p>
                      ))}
                  </CardContent>
                </Card>
              </CardBox>
            ))}
          </OuterWrapper>
        </div>
      </Container>
      <MobileRoadMap />
      <FooterBox>
        <FooterText>
          <div>ⓒMammoth Play Co., Ltd. All Rights Reserved</div>
          <div><span>E-MAIL:</span> business@mammothplay.io</div>
          <div><span>ADDRESS:</span> 26, Dosan-daero 28-gil, Gangnam-gu, Seoul, Republic of Korea</div>
          <TermsPage>
            <TermTitle href={"/privacy/kr"}>Privacy Policy</TermTitle>
            <span>|</span>
            <TermTitle href={"/terms/kr"}>Terms Of Service</TermTitle>
            <span>|</span>
            <TermTitle href={"/service/kr"}>Service Policy</TermTitle>
          </TermsPage>
        </FooterText>
      </FooterBox>
    </Wrapper>
  );
};
const Container = styled.div`
  width: 150%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  background-color: #1c1c1c;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const Section = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #1c1c1c;
  @media screen and (max-width: 820px) {
    height: 50vh;
  }
`;
const LastWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgb(70, 215, 255);
  background: linear-gradient(
    180deg,
    rgba(70, 215, 255, 1) 0%,
    rgba(214, 245, 124, 1) 50%,
    rgba(214, 247, 219, 1) 100%
  );
  background-position: bottom 10%;
  @media screen and (max-width: 820px) {
    height: 60vh;
  }
  @media screen and (max-width: 600px) {
    height: 50vh;
  }
  @media screen and (max-width: 480px) {
    height: 40vh;
  }
`;
const EllipseImg = styled.img`
  position: absolute;
  bottom: -30%;
  left: 0%;
  width: 100%;
  @media screen and (max-width: 1440px) {
    bottom: 0%;
  }
`;
const BgImg = styled.img`
  position: absolute;
  bottom: -25%;
  left: 0%;
  width: 100%;
  @media screen and (max-width: 1440px) {
    bottom: -10%;
  }
  @media screen and (max-width: 820px) {
    bottom: 0%;
  }
`;
const SectionWrapper = styled.div``;
const OuterWrapper = styled.div`
  display: flex;
  width: 150%;
  height: 100vh;
  padding: 8rem 0 0 8rem;
`;
const PageTitle = styled.div`
  width: 30rem;
  height: 100%;
  box-sizing: border-box;
  padding: 0 4rem;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const Title = styled.p`
  font-family: "Pretendard";
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: 820px) {
    font-size: 2rem;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(-50%, -50%);
  }
`;
const ArrowIcon = styled.img`
  width: 3.75rem;
  padding-top: 1.25rem;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const Card = styled.div`
  width: 375px;
  height: fit-content;
  margin-right: 3rem;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: black;
  font-family: "Pretendard";
  @media screen and (max-width: 820px) {
    width: 300px;
    margin-right: 0rem;
  }
`;
const CardBox = styled.div`
  @media screen and (max-width: 820px) {
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`;
const CardTop = styled.div``;
const PlanDate = styled.div`
  font-size: 16px;
  padding: 0.25rem 0;
`;
const PlanType = styled.div`
  font-size: 16px;
`;
const CardTitle = styled.div`
  padding: 1.25rem 0;
  font-size: 24px;
  font-weight: 500;
  @media screen and (max-width: 820px) {
    font-size: 20px;
  }
`;
const CardContent = styled.div`
  color: #fff;
`;
const ShowProgress = styled.div`
  position: absolute;
  bottom: 8%;
  left: 6%;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const ProgressBar = styled.div``;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: black;
`;
const ProgressRate = styled.progress`
  z-index: 1000;
  appearance: none;
  width: 100%;
  height: 5px;
  border: none;
  width: 50rem;
  height: 3px;
  animation-timeline: scroll(".container");
  &::-webkit-progress-bar {
    background-color: #5f5f5f;
  }
  &::-webkit-progress-value {
    background: linear-gradient(to left, #4ed7f5, #92e7bb);
    background-attachment: fixed;
  }
  &::-moz-progress-bar {
    background: linear-gradient(to left, #4ed7f5, #92e7bb);
    background-attachment: fixed;
  }
`;
const MobileTitle = styled.div`
  display: none;
  @media screen and (max-width: 820px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 4rem 0 0 0;
    font-family: "Pretendard";
    font-size: 2.25rem;
    font-weight: 700;
    color: #fff;
  }
`;
const FooterBox = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: url("/assets/images/commom_ico_dosa_logo_ver1.png"), url("/assets/images/Rectangle 24.png") #1c1c1c;
  background-position: 50% 50%, 10% 20%;
  background-repeat: no-repeat;
  background-size: 22%, 130%;
  font-family: "Pretendard";

  @media screen and (max-width: 1680px) {
    background-size: 30%, 200%;
  }
  @media screen and (max-width: 1080px) {
    background-size: 35%, 220%;
  }
  @media screen and (max-width: 700px) {
    background-size: 40%, 300%;
  }
  @media screen and (max-width: 500px) {
    background-position: 50% 50%, 20% 20%;
    background-size: 50%, 400%;
  }
  //&::after {
  //  position: absolute;
  //  bottom: 5%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //  content: "ⓒMammoth Play Co., Ltd. All Rights Reserved \A email \A address";
  //  white-space: pre;
  //  font-size: 18px;
  //  font-family: Pretendard-Regular;
  //  color: #b6b6b6;
  //  text-align: center;
  //  @media screen and (max-width: 500px) {
  //    bottom: 7%;
  //    width: 100%;
  //    font-size: 14px;
  //  }
  //}
`;

const FooterText = styled.div`
  position: absolute;
  bottom: 3%;
  left: 50%; 
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: "Pretendard";
  color: #b6b6b6;
  text-align: center;
  @media screen and (max-width: 500px) {
    bottom: 7%;
    width: 100%;
    font-size: 14px;
    > div:last-child {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  > div > span {
    color: white;
    font-family: "Pretendard";
    font-weight: 700;
  }
  > div:first-child {
    padding-bottom: 1.5rem;
  } 
  > div:not(:first-child) {
    padding-bottom: 0.25rem;
  }
`;

const TermsPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1.25rem;
  color: white;
  > a:hover{
    color: #b6b6b6;
    font-weight: 700;
    text-decoration: underline;
  }
`

const TermTitle = styled.a`
  font-weight: 700;
  text-decoration: underline;
`
export default SectionRoadMap;
