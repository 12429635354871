import styled from "styled-components";
import "../styles/term.css"
const TermsOfService = () => {
    return (
        <Wrapper>
            <SelectWrap>
                <SelectBox onChange={(e)=>{
                    let lang = e.target.value
                    window.location.replace(`https://dosasurvivor.com/terms/${lang}`)
                }}>
                    <SelectLanguage value={"kr"}>한국어</SelectLanguage>
                    <SelectLanguage value={"en"}>English</SelectLanguage>
                    <SelectLanguage value={"jp"}>日本語</SelectLanguage>
                </SelectBox>
            </SelectWrap>
            <TextWrap>
                <h1 id="-"><strong>이용약관</strong></h1>
                <h2 id="-1-"><strong>제1조(목적)</strong></h2>
                <p>이 약관은 맘모스플레이 주식회사(이하 “회사”라 합니다)가 제공하는 게임 서비스(이하 “서비스”라 합니다) 이용과 관련하여 회사와 이용자 간의 권리와의무 및 기타 필요한 제반 사항을 정함을
                    목적으로 합니다.</p>
                <h2 id="-2-"><strong>제2조(용어의 정의)</strong></h2>
                <p>이 약관에서 사용하는 용어의 정의는 다음 각호와 같으며, 정의하지 않은 용어의 해석은 관련 법령 및 관례에 따릅니다.</p>
                <ol>
                    <li><p>“이용 고객”이란 회사가 제공하는 서비스를 이용하기 위해 앱스토어 사업자 또는 플랫폼 사업자가 운영하는 앱스토어 마켓에서 애플리케이션을 다운로드한 자를 말합니다.</p>
                    </li>
                    <li><p>“이용자”란 이 약관 및 개인정보처리방침에 동의하고, 회사가 제공하는 서비스 이용 자격을 부여 받은 이용 고객을 말합니다.</p>
                    </li>
                    <li><p>“서비스”란 회사가 제공하는 게임 서비스 일체를 의미합니다.</p>
                    </li>
                    <li><p>“단말기”란 서비스를 이용할 수 있는 휴대폰, 스마트폰, PDA, 태블릿, 휴대용 게임기 등 유∙무선 기기를 말합니다.</p>
                    </li>
                    <li><p>“애플리케이션”이란 회사가 제공하는 서비스를 이용할 수 있는 프로그램 일체를 의미합니다.</p>
                    </li>
                    <li><p>“앱스토어 사업자”란 회사가 제공하는 애플리케이션을 다운로드 받을 수 있고, In-App 결제를 할 수 있도록 하는 오픈마켓 사업자를 말합니다.</p>
                    </li>
                    <li><p>“플랫폼 사업자”란 회사와 제휴하여 서비스를 제공하는 사업자 및 관련 서비스 일체를 말합니다.</p>
                    </li>
                    <li><p>“이용자 계정”이란 이용자의 식별과 서비스 이용을 위하여 이용자가 선정하고 앱스토어 사업자 또는 플랫폼 사업자가 부여하는 문자, 숫자 또는 특수문자의 조합을
                        의미합니다.</p>
                    </li>
                    <li><p>“임시계정(Guest ID)”이란 이용자 계정과 별도로 이용자의 식별과 서비스 이용을 위하여 플랫폼 사업자가 임의적으로 부여한 이용자 식별 번호를 의미합니다.</p>
                    </li>
                    <li><p>“콘텐츠”란 회사가 서비스에서 이용할 수 있도록 제작한 아이템 등을 말합니다.</p>
                    </li>
                    <li><p>“유료 콘텐츠”란 이용자가 서비스를 이용함에 있어 특정한 효과 또는 효능을 향유하기 위하여 In-App 결제를 통해 구매하는 콘텐츠를 의미합니다.</p>
                    </li>
                    <li><p>“무료 콘텐츠”란 이용자가 In-App 결제를 통해 구매하지 않고, 다른 이용자로부터 선물받거나 서비스를 이용하면서 무료로 취득한 콘텐츠를 의미합니다.</p>
                    </li>
                    <li><p>“In-App 결제”란 애플리케이션 내에서 유료 콘텐츠를 구매하기 위한 결제 행위를 말합니다.</p>
                    </li>
                </ol>
                <h2 id="-3-"><strong>제3조(회사정보 등의 제공)</strong></h2>
                <p>회사는 다음 각호의 사항을 회사의 웹사이트에 게시하거나 애플리케이션 내의 연결 화면을 통해 이용자가 이를 쉽게 알 수 있도록 합니다. 다만, 이 약관 및 개인정보처리방침은 이용자가 연결
                    화면을 통하여 볼 수 있도록 할 수 있습니다.</p>
                <ol>
                    <li><p>상호 및 대표자의 성명</p>
                    </li>
                    <li><p>영업소 소재지 주소(이용자의 불만을 처리할 수 있는 곳의 주소를 포함한다) 및 전자우편주소</p>
                    </li>
                    <li><p>전화번호, FAX 번호</p>
                    </li>
                    <li><p>사업자등록번호, 통신판매업 신고번호</p>
                    </li>
                    <li><p>개인정보처리방침</p>
                    </li>
                    <li><p>서비스이용약관</p>
                    </li>
                </ol>
                <h2 id="-4-"><strong>제4조(약관의 효력 및 변경)</strong></h2>
                <p>① 이 약관은 이용자가 알 수 있도록 회사의 웹사이트에 게시하거나 어플리케이션 내의 연결 화면 등을 통해 이용자에게 공지함으로써 효력이 발생합니다.</p>
                <p>② 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
                    「게임산업진흥에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                    「콘텐츠산업진흥법」 등 관련 법령에 위배되지 않는 범위에서 이 약관을 변경할 수 있습니다. 변경되는 약관은 적용일자, 변경내용 및 변경사유 등을 명시하여 그 적용일 7일 이전부터 서비스
                    공식 블로그 또는 단말기 알림(Push 알림) 등을 통해 이용자에게 공지합니다. 다만, 이용자의 권리∙의무에 중대한 영향을 미치는 사항에 대해서는 그 적용일 30일 이전부터
                    공지합니다.</p>
                <p>③ 이용자는 변경되는 약관에 대해 동의하지 않을 수 있으며, 변경되는 약관에 동의하지 않는 경우에는 서비스 이용을 중단하고 서비스에서 탈퇴를 할 수 있습니다. 다만, 제2항의 방법으로
                    변경되는 약관 공지 시 이용자가 별도의 의사표시를 하지 않으면 승낙한 것으로 본다고 공지하였음에도 불구하고, 변경되는 약관의 적용일 전일까지 회사에 대해 명시적으로 의사표시를 하지
                    아니하는 경우 또는 이용자가 변경되는 약관 적용일 이후에도 계속하여 서비스를 이용하는 경우에는 변경된 약관에 동의한 것으로 봅니다.</p>
                <h2 id="-5-"><strong>제5조(약관 외 준칙)</strong></h2>
                <p>이 약관에서 정하지 아니한 사항에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「게임산업진흥에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에
                    관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령 및 상관례에 따릅니다.</p>
                <h2 id="-6-"><strong>제6조(이용 계약의 성립)</strong></h2>
                <p>① 서비스 이용계약은 이용 고객이 애플리케이션을 설치 및 구동하여 이 약관 및
                    개인정보처리방침에 동의한 다음 서비스 이용신청을 하고, 회사가 그 이용신청에 승낙함으로써 성립합니다. 이용 고객의 서비스 이용신청 완료 후 단말기내에서 애플리케이션이 정상적으로 구동되는
                    경우에는 서비스 이용이 승낙된 것으로 봅니다.</p>
                <p>② 이용자는 서비스 이용과정에서 타인의 개인정보를 도용하는 경우 이 약관에 의한 이용자의 권리를 주장할 수 없으며, 회사는 이용계약을 취소하거나 해지할 수 있습니다. 이용자가 플랫폼
                    사업자에게 개인정보를 제공하고 플랫폼 사업자를 통해 서비스를 이용하는 경우에도 동일하게 간주됩니다.</p>
                <p>③ 회사는 다음 각호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.</p>
                <ol>
                    <li><p>서비스 운영정책에 따라 최근 3개월 내 이용제한을 받았거나, 영구제한을 받은 이용자가 이용신청을 하는 경우</p>
                    </li>
                    <li><p>회사가 체결한 계약에 따라 또는 특정 국가에서 접속하는 이용자에게 서비스를 제공하는 것을 제한할 필요가 있는 경우</p>
                    </li>
                    <li><p>「게임산업진흥에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 그 밖의 관련 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우</p>
                    </li>
                    <li><p>부정한 용도로 게임서비스를 이용하고자 하는 경우</p>
                    </li>
                    <li><p>그 밖에 제1호 내지 제3호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</p>
                    </li>
                </ol>
                <p>④ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.</p>
                <ol>
                    <li><p>회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 있는 경우</p>
                    </li>
                    <li><p>서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한 경우</p>
                    </li>
                    <li><p>그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우</p>
                    </li>
                </ol>
                <h2 id="-7-"><strong>제7조(이용자 계정 관리)</strong></h2>
                <p>① 회사는 이용자 계정(임시 계정 포함. 이하 같습니다)을 통하여 이용자의 서비스 이용 가능 여부 등 제반 이용자 관리업무를 수행합니다.</p>
                <p>② 이용자는 자신의 이용자 계정을 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회사는 이용자가 자신의 이용자 계정을 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는
                    손해에 대해 어떠한 책임도 부담하지 않습니다.</p>
                <h2 id="-8-"><strong>제8조(개인정보의 보호 및 관리)</strong></h2>
                <p>① 회사는 관련 법령이 정하는 바에 따라 이용자 계정을 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자의 개인정보 보호 및 이용에 대해서는 관련 법령 및 회사가 별도로 정하여
                    고지한 개인정보처리방침에 따릅니다.</p>
                <p>② 회사의 웹사이트 또는 서비스에서 단순히 링크된 제3자 제공의 서비스 또는 광고에 대하여는 회사의 개인정보처리방침이 적용되지 않습니다.</p>
                <p>③ 회사는 이용자의 귀책사유로 인하여 노출된 이용자 계정을 포함한 이용자의 개인정보에 대해서는 일체의 책임을 지지 않습니다.</p>
                <h2 id="-9-"><strong>제9조(회사의 의무)</strong></h2>
                <p>① 회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의칙에 따라 성실하게 이행합니다.</p>
                <p>② 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고, 객관적으로 인정될 경우에는 합리적인 기간내에 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 이용자에게 그 사유와
                    처리일정을 별도로 고지합니다.</p>
                <p>③ 회사는 서비스에 장애가 발생하는 경우 천재지변, 비상사태 등 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</p>
                <p>④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.</p>
                <h2 id="-10-"><strong>제10조(이용자의 의무)</strong></h2>
                <p>① 이용자는 회사에서 제공하는 서비스의 이용과 관련하여 본 약관 및 운영정책 등에 따라 이용하여야 하며, 다음 각 호에 해당하는 행위를 해서는 안 됩니다.</p>
                <ol>
                    <li><p>이용신청 또는 회원 정보 변경 시 허위사실을 기재하는 행위</p>
                    </li>
                    <li><p>회사가 제공하지 않는 서비스나 비정상적인 방법을 통해 회원의 계정정보 또는 사이버 자산(ID, 캐릭터, 아이템, 게임 머니 등)을 매매 또는 증여하거나, 이를 취득하여
                        이용하는 행위</p>
                    </li>
                    <li><p>회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위</p>
                    </li>
                    <li><p>타인의 신용카드‧유/무선 전화‧은행 계좌 등을 도용하여 유료 콘텐츠를 구매하는 행위, 다른 이용자의 ID 및 비밀번호를 부정사용하는 행위</p>
                    </li>
                    <li><p>다른 이용자의 개인정보를 무단으로 수집‧저장‧게시 또는 유포하는 행위</p>
                    </li>
                    <li><p>도박 등 사행행위를 하거나 유도하는 행위, 음란‧저속한 정보를 교류‧게재하거나 음란 사이트를 연결(링크)하는 행위, 수치심‧혐오감 또는 공포심을 일으키는
                        말‧소리‧글‧그림‧사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등 서비스를 불건전하게 이용하는 행위</p>
                    </li>
                    <li><p>서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위</p>
                    </li>
                    <li><p>이용자의 서비스를 이용하여 얻은 정보를 무단으로 복제‧유통‧조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위</p>
                    </li>
                    <li><p>타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위</p>
                    </li>
                    <li><p>회사나 타인의 지식재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위</p>
                    </li>
                    <li><p>법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어‧하드웨어 또는 전기통신장비의 정상적인 작동을 방해‧파괴할 목적으로 고안된 바이러스‧컴퓨터
                        코드‧파일‧프로그램 등을 고의로 전송‧게시‧유포 또는 사용하는 행위</p>
                    </li>
                    <li><p>회사로부터 특별한 권리를 부여 받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가‧삽입하거나, 서버를 해킹‧역설계하거나, 소스 코드나 애플리케이션
                        데이터를 유출‧변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경‧도용하여 회사를 사칭하는 행위</p>
                    </li>
                    <li><p>그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위</p>
                    </li>
                </ol>
                <p>① 이용자의 계정 및 모바일 기기에 관한 관리 책임은 이용자에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안됩니다. 모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해
                    발생하는 손해에 대해서 회사는 책임을 지지 않습니다.</p>
                <p>② 이용자는 각 오픈마켓에서 부정한 결제가 이루어지지 아니하도록 결제 비밀번호 기능을 설정하고 관리하여야 합니다. 이용자의 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지
                    않습니다.</p>
                <p>③ 회사는 다음 각 호의 행위의 구체적인 내용을 운영정책 등에서 정할 수 있으며, 이용자는 이를 따라야 합니다.</p>
                <ol>
                    <li><p>회원의 계정명, 캐릭터명, 길드명, 기타 게임 내에서 사용하는 명칭</p>
                    </li>
                    <li><p>채팅내용과 방법</p>
                    </li>
                    <li><p>게시판이용 및 서비스이용 방법</p>
                    </li>
                    <li><p>기타 회원의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 회사가 서비스의 운영상 필요하다고 인정하는 사항</p>
                    </li>
                </ol>
                <h2 id="-11-"><strong>제11조(서비스의 이용 및 중단 등)</strong></h2>
                <p>① 회사는 이용자에게 서비스 이용을 승낙한 때부터 서비스를 개시합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 제공합니다.</p>
                <p>② 이용자가 임시계정(Guest ID)를 사용하여 서비스를 이용하는 경우에는 단말기에서 애플리케이션 삭제하거나 단말기를 교체(변경)할 경우 유료 콘텐츠 및 서비스 이용기록이 삭제될 수
                    있음으로 이용자 계정을 사용하여 서비스를 이용하는 것을 권장합니다. 회사는 이용자가 임시계정(Guest ID)으로 서비스를 이용하는 경우 최초 서비스 이용시점에 이러한 내용을 사전에
                    고지하며, 애플리케이션 삭제 혹은 단말기 교체 등 임시계정으로 서비스 이용 중 발생한 손해에 대해서는 어떠한 책임도 부담하지 않습니다.다만, 회사의 고의 또는 과실에 의한 경우에는
                    그러하지 아니합니다.</p>
                <p>③ 네트워크를 통해 애플리케이션을 다운로드하거나 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다. 또한, 단말기 변경, 해외 로밍 등의 경우에는
                    콘텐츠의 전부 또는 일부 이용이 불가능할 수 있으며, 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우
                    단말기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을 지지 않습니다.</p>
                <p>④ 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 서비스를 제공합니다. 다만, 시스템 정기점검, 서버의 증설 및 교체, 각종 버그 패치, 서비스 변경 등 운영상
                    필요한 경우 일정기간 동안 서비스 이용을 일시 중단할 수 있습니다. 이러한 경우 회사는 그 내용 및 시간을 서비스 공식 블로그 또는 애플리케이션내의 공지사항 등을 통해 사전에 공지합니다.
                    다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우에는 사후에 공지할 수 있습니다.</p>
                <p>⑤ 회사는 영업양도, 분할∙합병 등에 따른 서비스의 폐지, 서비스 제공 계약 만료, 해당 서비스의 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를
                    중단할 수 있습니다. 이 경우 서비스 중단일 30일 이전에 중단사유 및 보상조건 등을 제4조 제2항에서 정한 방법으로 공지하며, 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단
                    공지시 공지된 서비스 중단일까지로 합니다.</p>
                <h2 id="-12-"><strong>제12조(서비스 내용 및 변경)</strong></h2>
                <p>① 이용자는 이 약관 및 서비스 운영정책 또는 이용규칙에 따라 서비스를 이용하여야 합니다.</p>
                <p>② 회사는 서비스의 운영, 종료 등 서비스에 관한 포괄적인 권한을 가집니다.</p>
                <p>③ 회사는 다음 각호의 어느 하나에 해당되는 경우 서비스의 전부 또는 일부를 제한하거나 중지 또는 종료할 수 있습니다.</p>
                <ol>
                    <li><p>전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우</p>
                    </li>
                    <li><p>정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</p>
                    </li>
                    <li><p>서비스 설비의 보수 등 공사로 인한 부득이한 경우</p>
                    </li>
                </ol>
                <p>기타 회사의 제반 사정으로 서비스를 할 수 없는 경우</p>
                <p>④ 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 과실로 인한 경우를 제외하고 서비스의 변경 및/또는 중지로 인하여 발생하는 문제에 대해 책임을 지지 않습니다.</p>
                <h2 id="-13-"><strong>제13조(정보의 제공 및 광고 게재)</strong></h2>
                <p>① 회사는 플랫폼 사업자 및/또는 앱스토어 사업자를 통해 제공받은 이용자 정보외에 이용자에게 추가정보를 요청할 수 있으며, 수집 또는 제공받은 이용자 정보에 대해서는 개인정보처리방침에서
                    정한 목적외로 이용하지 않습니다.</p>
                <p>② 회사는 서비스에 광고를 게재할 수 있으며, 이용자는 서비스 이용시 광고가 노출되는 것에 대하여 동의합니다.</p>
                <p>③ 회사는 이용자가 제2항의 광고에 접속∙참여∙거래 등을 함으로 인하여 발생하는 손실 또는 손해에 대해 어떠한 책임도 부담하지 않습니다.</p>
                <p>④ 회사는 단말기 알림(Push 알림)을 활용하여 이용자에게 광고를 발송할 수 있으며, 이용자는 언제든지 애플리케이션내의 수신거부 기능을 이용하여 수신을 거부할 수 있습니다.</p>
                <h2 id="-14-"><strong>제14조(유료 콘텐츠의 구매 등)</strong></h2>
                <p>① 이용자는 서비스를 이용하고 있는 단말기의 종류에 따른 앱스토어 사업자의 결제정책에 따라 유료 콘텐츠를 구매할 수 있으며, 해당 결제 정책의 차이로 인하여 결제금액에 차이가 발생할 수
                    있습니다. 또한 유료 콘텐츠의 구매 대금은 앱스토어 사업자와 연계된 이동통신사나 플랫폼 사업자 및/또는 앱스토어 사업자가 정하는 방법 및 정책에 따라 부과되며, 납부방식 역시 해당
                    사업자의 결제정책을 따릅니다.</p>
                <p>② 이용자가 서비스에서 구매한 유료 콘텐츠는 해당 서비스 애플리케이션을 다운로드 받아 설치한 단말기에서만 이용할 수 있습니다.</p>
                <p>③ 이용자가 유료 콘텐츠를 서비스내의 콘텐츠로 전환한 경우 전환한 콘텐츠의 사용기간은 전환일로부터 1년이며, 이 기간이 경과한 경우 콘텐츠 사용권은 소멸됩니다. 다만, 사용기간이 별도로
                    명시된 콘텐츠의 경우에는 그 기간에 따릅니다. 또한, 콘텐츠 사용기간 중 회사의 서비스 변경, 종료 등으로 인하여 콘텐츠를 더 이상 사용할 수 없는 경우, 동일/유사한 콘텐츠로
                    보상합니다.</p>
                <p>④ 이용자는 유료 콘텐츠를 이용자 계정에서만 이용할 수 있으며, 제3자에게 양도, 대여, 매매 또는 환전 등을 할 수 없습니다. 다만, 회사가 별도로 정하여 공지한 방법으로 이용인 경우에는
                    그러하지 않습니다.</p>
                <h2 id="-15-in-app-"><strong>제15조(In-App 결제)</strong></h2>
                <p>① 애플리케이션은 유료 콘텐츠 구매를 위한 In-App 결제 기능을 포함하고 있습니다.</p>
                <p>② 이용자는 단말기의 비밀번호 설정기능, 앱스토어 사업자 및/또는 이동통신사가 제공하는 비밀번호 설정기능 등을 이용하여 제3자의 In-App 결제를 방지하여야 합니다.</p>
                <p>③ 회사는 이용자가 In-App 결제 방지기능 등을 이용하지 않거나 이용자의 부주의로 비밀번호가 노출되어 발생하는 제3자의 In-App 결제에 대해 어떠한 책임도 부담하지 않습니다.</p>
                <p>④ 이용자가 이동통신사의 청소년 요금제에 가입한 경우, 해당 단말기에서의 In-App 결제를 하면 그 내용은 법정대리인의 동의가 있는 것으로 간주합니다.</p>
                <p>⑤ 이용자는 In-App 결제 대금을 성실히 납부하여야 할 책임이 있습니다.</p>
                <p>⑥ 회사의 정책 및 결제업체(이동통신사, 앱스토어 사업자 등)의 정책, 방침 등에 따라 각 결제수단별로 결제한도가 부여 또는 조정될 수 있습니다.</p>
                <h2 id="-16-"><strong>제16조(청약 철회 및 환불 등)</strong></h2>
                <p>① 이용자는 구매한 유료 콘텐츠를 구매일 또는 유료 콘텐츠 이용가능일로부터 7일 이내에, 유료 콘텐츠의 내용이 표시∙광고의 내용과 다르거나 구매 내용과 다르게 이행된 경우에는 유료 콘텐츠의
                    이용 가능일부터 3개월 이내 또는 그 사실을 안 날이나 알 수 있었던 날부터 30일 이내에 별도의 수수료 없이 청약철회(구매 취소)를 할 수 있습니다. 다만, 청약철회 요청시 이미
                    사용하였거나 사용한 것으로 간주되는 유료 콘텐츠 등 이에 준하는 특성을 가진 유료 콘텐츠에 대하여는 청약철회(구매취소)가 제한될 수 있습니다.</p>
                <p>② 회사는 회사의 귀책사유로 인하여 이용자가 구매한 유료 콘텐츠를 서비스에서 이용하지 못하는 경우, 동일∙유사한 유료 콘텐츠로 보상하거나 해당 구매 금액을 구매일에 관계없이 전액
                    환불합니다.</p>
                <p>③ 이용자가 구매한 유료 콘텐츠에 대한 환불은 이용자가 이용한 앱스토어 사업자의 환불 정책에 따라 진행되며, 자세한 환불 신청절차는 앱스토어 사업자의 운영정책에 따릅니다. 또한 구매한 유료
                    콘텐츠에 대한 환불이 완료된 경우에는 환불 금액만큼 유료 콘텐츠가 차감됩니다. 또한, 유료 콘텐츠가 다르게 지급된 경우에는 본조 제2항에서 정한 방법에 따릅니다. 다만, 이용자 다르게
                    지급된 유료 콘텐츠 일부를 사용한 경우에는 유료 콘텐츠로만 보상합니다.</p>
                <p>④ 유료 콘텐츠를 콘텐츠로 전환한 경우, 전부 또는 일부 사용으로 인하여 서비스내에서 구매할 수 있는 기본 단위가 훼손된 경우, 메시지함 및/또는 선물함 등 유료 콘텐츠의 송/수신과 관련된
                    화면에서 수락 등의 동의 과정을 거친 경우 등 이용자의 유료 콘텐츠 이용으로 간주될 수는 사유가 있는 때에는 이용자가 사용의사를 밝힌 것으로 간주됩니다.</p>
                <p>⑤ 이용자가 정상적인 구매내역이 기록되는 In-App 결제를 통하지 않고 서비스 이용행위로 취득하거나 다른 이용자로부터 선물 받은 유료 콘텐츠, 회사의 이벤트 등을 통하여 취득한 무료
                    콘텐츠는 환불되지 않습니다.</p>
                <p>⑥ 회사는 청약철회가 제한되는 유료 콘텐츠에 대해 In-App 결제 전에 청약철회가 제한됨을 표시하는 등의 방법으로 청약철회 등의 권리행사가 방해 받지 아니하도록 조치 합니다. 만약 회사가
                    이러한 조치를 하지 아니한 경우에는 청약철회 제한 사유에도 불구하고 이용자는 청약철회를 할 수 있습니다. 다만, 다음 각호의 경우에는 청약철회가 제한됩니다.</p>
                <ol>
                    <li><p>구매 후 즉시 사용이 시작되거나 즉시 서비스에 적용되는 유료 콘텐츠의 경우</p>
                    </li>
                    <li><p>추가적인 혜택이 제공된 유료 콘텐츠 구매 후 해당 추가 혜택이 사용된 경우</p>
                    </li>
                    <li><p>묶음형(패키지형)으로 판매된 유료 콘텐츠의 일부가 사용된 경우</p>
                    </li>
                    <li><p>개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는 캡슐형/확률형 유료 콘텐츠를 개봉한 경우</p>
                    </li>
                    <li><p>유료 콘텐츠의 일부를 사용하였거나 이미 시간이 지나 다시 판매하기 곤란한 경우</p>
                    </li>
                    <li><p>기간제 상품으로 결제되어 사용되는 콘텐츠일 경우</p>
                    </li>
                </ol>
                <p>⑦ 미성년자가 법정대리인의 동의없이 In-App 결제로 유료 콘텐츠를 구매한 경우, 미성년자 또는 법정대리인은 In-App 결제를 취소할 수 있습니다. 다만, 미성년자의 In-App 결제가
                    법정대리인으로부터 처분을 허락받은 재산의 범위 내인 경우 또는 미성년자가 사술 등을 사용하여 성년자로 믿게 한 때에는 취소가 제한됩니다. 유료 콘텐츠 구매자가 미성년자인지 여부는
                    In-App 결제가 진행된 단말기 또는 신용카드 등 결제수단의 명의자를 기준으로 판단됩니다. 미성년자의 결제취소를 요청하시는 경우 회사의 필요에 따라 미성년자 및 법정대리인을 증명할 수
                    있는 서류를 제출하여야 합니다.</p>
                <p>⑧ In-App 결제는 앱스토어 사업자가 제공하는 결제방식에 따르며. In-App 결제 과정에서 과오납금이 발생하는 경우 앱스토어 사업자에게 환불을 요청하여야 합니다. 만약, In-App
                    결제과정에서 과오납금이 발생하여 회사에 환불(결제취소 포함) 등을 요청하는 경우 회사는 앱스토어 사업자의 정책, 시스템에 따라 가능한 경우에 한하여 앱스토어 사업자에게 과오납금 환불을
                    요청하여 환불을 진행하며, 과오납금 환불 방식은 제17조 제3항에 따릅니다. 다만, 앱스토어 사업자가 회사의 과오납금 환불 신청을 제한하고 있는 경우에는 이용자가 직접 앱스토어 사업자에게
                    과오납금 환불을 요청하여야 합니다.</p>
                <h2 id="-17-"><strong>제17조(청약 철회 등의 효과)</strong></h2>
                <p>① 회사는 이용자가 제16조 제1항 본문에 따른 청약철회를 한 경우 지체없이 해당 유료 콘텐츠를 회수 또는 삭제하고, 유료 콘텐츠를 회수 또는 삭제한 날로부터 3영업일 이내에 지급받은
                    대금을 환불하거나 결제취소를 통해 환불 합니다.</p>
                <p>② 제1항의 경우 회사가 이용자에게 환불을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률」 및 같은 법 시행령에서 정하는 이율을 곱하여 산정한 지연이자를
                    지급합니다.</p>
                <p>③ 회사는 환불을 함에 있어 이용자가 신용카드, 그 밖에 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」에서 정한 결제수단으로 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한
                    사업자로 하여금 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 회사가 결제업자로부터 이미 대금을 지급받은 때에는 이를 결제업자에게 환불하고 이를 이용자에게 통지합니다.</p>
                <p>④ 이용자가 제16조 제1항 본문에 따른 청약철회를 한 경우 유료 콘텐츠 등의 반환에 필요한 비용은 이용자가 부담(표시∙광고의 내용과 다르거나 구매 내용과 다르게 이행된 경우 제외)하고,
                    회사는 이용자에게 청약철회를 이유로 위약금 또는 손해배상을 청구하지 않습니다.</p>
                <p>⑤ “회사”는 아이템 등이 일부 사용되거나 소비된 경우에는 “이용자”가 얻은 이익 또는 해당 아이템 등의 공급에 든 비용에 상당하는 금액을 “이용자”에게 청구할 수 있습니다.</p>
                <p>⑥ “회사”는 필요한 경우 “이용자”에게 환급에 필요한 서류를 요구할 수 있으며, “이용자”가 이를 거부할 경우에는 환급이 제한될 수 있습니다.</p>
                <h2 id="-18-"><strong>제18조(계약 해지 및 서비스 이용 중지 등)</strong></h2>
                <p>① 이용자는 언제든지 서비스의 이용을 원하지 않는 경우, 애플리케이션내의 탈퇴를 이용하여 이용계약을 해지할 수 있습니다.</p>
                <p>② 회사는 서비스 이용계약이 해지(탈퇴)되는 경우 서비스 이용기록, 유∙무료 콘텐츠 등을 지체없이 삭제합니다. 따라서, 이용자는 서비스 이용계약 해지(탈퇴)되기 전까지 유료 콘텐츠의 환불을
                    요청하여야 합니다. 다만, 회사는 이용자가 서비스 이용계약의 해지(탈퇴)를 신청하는 경우, 조작 실수 등으로 인하여 발생하는 피해를 예방하기 위해 유예기간(신청일로부터 14일)이 만료된
                    후 서비스 이용기록 등을 삭제(탈퇴 처리)하며, 이용자는 유예기간내에는 언제든지 서비스 이용계약의 해지(탈퇴)신청을 철회할 수 있습니다.</p>
                <p>③ 회사는 이용자가 제10조 또는 서비스 운영정책에서 정한 사항을 위반하는 경우 이용계약을 해지하거나 기간을 정하여 서비스 이용을 제한할 수 있습니다.</p>
                <p>④ 이용자는 제3항에 따른 서비스 이용제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 이용자의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을
                    재개합니다.</p>
                <p>⑤ 회사는 제3항에 따른 서비스 이용제한이 정당한 경우 서비스 이용제한으로 인하여 이용자가 입은 손해를 배상할 책임을 부담하지 않습니다.</p>
                <p>⑥ 회사는 이용자의 서비스 과몰입 방지를 위해 이용방법, 이용시간, 이용횟수 등을 제한할 수 있으며, 이용자의 서비스 과몰입 정도에 따라 이용자 보호프로그램을 적용할 수 있습니다. 만약,
                    이용자가 이용자 보호프로그램의 적용을 거부하는 경우, 회사는 이용자의 의사와 관계없이 서비스 제공을 중단할 수 있습니다.</p>
                <p>⑦ 회사는 이용자가 연속하여 1년 동안 서비스를 이용하지 않은 경우 이용자의 개인정보를 보호하기 위해 이용계약을 해지하고 이용자의 개인정보파기 또는 분리보관 등의 조치를 취할 수 있습니다.
                    이 경우 조치일 30일 전까지 조치 내용 등을 이용자에게 통지합니다.</p>
                <h2 id="-19-"><strong>제19조(잠정조치로서의 이용제한)</strong></h2>
                <p>① 회사는 다음 각호의 어느 하나에 해당하는 문제에 대한 조사가 완료될 때까지 이용자의 서비스 이용을 잠정적으로 중지할 수 있습니다.</p>
                <ol>
                    <li><p>이용자 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우</p>
                    </li>
                    <li><p>서비스 운영정책에서 정하고 있는 제재대상 행위에 해당된다고 합리적으로 의심되는 경우</p>
                    </li>
                    <li><p>그 밖에 제1호 및 제2호에 준하는 행위에 해당된다고 판단되는 경우</p>
                    </li>
                </ol>
                <p>② 회사는 제1항 각호의 조사가 완료된 후 서비스 이용기간에 비례하여 유료 콘텐츠의 이용기간을 연장합니다. 다만, 제1항에 의한 위법행위자로 확인된 경우에는 연장되지 않습니다.</p>
                <h2 id="-20-"><strong>제20조(손해배상)</strong></h2>
                <p>① 이용자는 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는 서비스를 이용하는 과정에서 회사에 손해를 입힌 경우 회사에 대하여 그 손해를 배상하여야 합니다.</p>
                <p>② 이용자는 서비스 이용과정에서 행한 불법행위나 이 약관 위반행위로 인하여 회사가 이용자 이외의 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는 경우, 자신의 책임과
                    비용으로 회사를 면책시켜야 하며, 회사가 면책되지 않음으로 인하여 회사에 손해가 발생한 경우 그 손해를 배상하여야 합니다.</p>
                <h2 id="-21-"><strong>제21조(면책)</strong></h2>
                <p>① 회사는 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 그 책임이 면제됩니다.</p>
                <p>② 회사는 이용자의 귀책사유로 인한 서비스의 중지∙이용장애 등에 대해 책임을 지지 않으며, 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 못함으로 인하여 이용자에게 손해가
                    발생한 경우에 대해서도 책임을 부담하지 않습니다.</p>
                <p>③ 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</p>
                <p>④ 회사는 사전에 공지되거나 긴급하게 실시된 서비스 설비의 보수, 교체, 정기점검 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서는 그 책임이 면제됩니다.</p>
                <p>⑤ 회사는 이용자가 서비스를 이용하여 기대하는 점수, 순위 등을 얻지 못한 것에 대하여 책임을 지지 않으며, 서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이
                    면제됩니다.</p>
                <p>⑥ 회사는 이용자가 본인의 개인정보 등(이용자 계정 포함)을 변경하여 얻는 불이익 및 정보상실에 대해 일체 책임을 지지 않습니다.</p>
                <p>⑦ 회사는 이용자의 단말기 환경, 회사의 귀책사유 없는 네트워크 환경 등으로 인하여 서비스 이용과정에서 발생하는 제반 문제에 대해서 책임을 지지 않습니다.</p>
                <p>⑧ 회사는 이용자 상호간 또는 이용자와 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.</p>
                <p>⑨ 회사가 제공하는 서비스 중 무료 서비스 또는 무료 콘텐츠의 경우에는 손해배상의 대상에서 제외됩니다. 다만, 회사의 고의 또는 중과실로 인하여 발생한 손해의 경우는 제외합니다.</p>
                <h2 id="-22-"><strong>제22조(재판권 및 준거법)</strong></h2>
                <p>① 서비스 이용과 관련하여 회사와 이용자간에 발생한 분쟁에 관한 소송은 민사소송법 등 관련법령에서 정한 절차에 따른 법원을 관할법원으로 합니다.</p>
                <p>② 회사와 이용자간에 제기된 소송에는 대한민국 법을 적용합니다.</p>
                <p>[부칙]</p>
                <p>(시행일) 이 약관은 2024년 4월 30일부터 적용됩니다.</p>
            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;

`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectWrap = styled.div`
    width: 60%;
    text-align: end;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectBox = styled.select`
    padding: 0.25rem;
    border: 1px solid black;
`
const SelectLanguage = styled.option``

export default TermsOfService