import React from "react";
import styled from "styled-components";
import ImageScale from "./ImageScale";
import SecondImageScale from "./SecondImageScale";
import MobileImages from "./MobileImages";

const AnimationImage = () => {
  return (
    <Wrapper>
      <WebScreen>
        <ImageScale />
        <SecondImageScale />
      </WebScreen>
      <MobileImages />
      <Parts src="/assets/images/common_ico_ object_01.png" alt="page-parts-1" />
      <SecondParts src="/assets/images/common_ico_ object_02.png" alt="page-parts-2" />
      <ThirdParts src="/assets/images/common_ico_ object_03.png" alt="page-parts-3" />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  background: rgb(214, 247, 219);
  background: linear-gradient(
    180deg,
    rgba(214, 247, 219, 1) 0%,
    rgba(109, 214, 200, 1) 50%,
    rgba(70, 215, 255, 1) 100%
  );
`;
const WebScreen = styled.div`
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const Parts = styled.img`
  position: absolute;
  top: -5%;
  left: -2%;
  width: 20rem;
  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    top: -10%;
    width: 10rem;
  }
  @media screen and (max-width: 500px) {
    left: -2%;
    width: 7rem;
  }
`;
const SecondParts = styled.img`
  position: absolute;
  bottom: 50%;
  right: 5%;
  width: 10rem;
  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    bottom: 50%;
    right: 5%;
    width: 7rem;
  }
  @media screen and (max-width: 500px) {
    width: 4rem;
    bottom: 55%;
  }
`;
const ThirdParts = styled.img`
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    top: 80%;
    width: 7rem;
  }
  @media screen and (max-width: 500px) {
    top: 80%;
    width: 4rem;
  }
`;
export default AnimationImage;
