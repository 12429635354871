import styled from "styled-components";
import TableEN from "./TableEN";

const ServicePolicy = () => {
  return (
    <Wrapper>
      <SelectWrap>
        <SelectBox
          onChange={(e) => {
            let lang = e.target.value;
            window.location.replace(`https://dosasurvivor.com/service/${lang}`);
          }}
        >
          <SelectLanguage value={"kr"}>한국어</SelectLanguage>
          <SelectLanguage value={"en"} selected={true}>
            English
          </SelectLanguage>
          <SelectLanguage value={"jp"}>日本語</SelectLanguage>
        </SelectBox>
      </SelectWrap>
      <TextWrap>
        <h1 id="-">
          <strong>Service Policy</strong>
        </h1>
        <br />
        <br />
        <p>Effective Date: 09. 05. 2024</p>
        <h2 id="-1-">
          <strong>1. General Principles</strong>
        </h2>
        <ul>
          <li>
            Restriction periods suspending users from the service are applied
            depending on individual user accounts and types of behavior, and
            restriction apply to all services used by the same restricted user
            account.
          </li>
          <li>
            The company may restrict users who engage in actions subject to ban
            after confirmation via public information received by the Help
            Center or various organizations, information from police
            investigations, or user monitoring.
          </li>
          <li>
            Even if the company deems that the user's behavior does not
            precisely meet the criteria subject to a restriction, the user may
            be restricted from the service in accordance with service use
            policies that have contravened the service Terms and Conditions.
          </li>
          <li>
            If a user performs an action or method subject to restrict to create
            or acquire in-game currency, experience points, points, items, etc.
            ("content", below), the company may restrict the user from the
            service and separately remove or reset created or acquired content.
            Furthermore, even if the behavior is suspected to be objectively
            illegal user behavior (including receipt of content created/acquired
            via actions subject to ban), the company may restrict the user from
            the service and separately remove or reset created or acquired
            content.
          </li>
          <li>
            The service restriction will be instituted upon confirmation of the
            user's action or method and the time of action and time of user ban
            may differ. If the user applies to leave during the restriction
            period, service use will be limited or denied until the restriction
            period has concluded.
          </li>
          <li>
            In the absence of objective evidence or if the user account is
            already restricted or deleted, no restriction will be enacted.
            However, if repeated/habitual action pertaining to a more serious
            reason for suspension is discovered through investigation, further
            action may be taken even if a ban is already imposed.
          </li>
        </ul>
        <br />
        <h2 id="-2-">
          <strong>2. Restriction Policy</strong>
        </h2>
        <TableEN />
      </TextWrap>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 3rem 0;
  background-color: white;
  color: black;
`;
const TextWrap = styled.div`
  width: 60%;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectWrap = styled.div`
  width: 60%;
  text-align: end;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectBox = styled.select`
  padding: 0.25rem;
  border: 1px solid black;
`;
const SelectLanguage = styled.option``;

export default ServicePolicy;
