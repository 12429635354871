import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";

import Contents from "./components/Contents";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfServiceEN from "./components/TermsOfServiceEN";
import PrivacyPolicyEN from "./components/PrivacyPolicyEN";
import TermsOfServiceJP from "./components/TermsOfServiceJP";
import PrivacyPolicyJP from "./components/PrivacyPolicyJP";
import ServicePolicy from "./components/ServicePolicy";
import ServicePolicyEN from "./components/ServicePolicyEN";
import ServicePolicyJP from "./components/ServicePolicyJP";
import PrivacyCalifornia from "./components/PrivacyCalifornia";
import UserRights from "./components/UserRights";

function App() {
  /* hide console */
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.warn = function () {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
      <Routes>
          <Route path={"/"} element={<Contents />}/>
          {/*terms of service*/}
          <Route path={"/terms/kr"} element={<TermsOfService />}/>
          <Route path={"/terms/en"} element={<TermsOfServiceEN />}/>
          <Route path={"/terms/jp"} element={<TermsOfServiceJP />}/>
          {/*privacy policy*/}
          <Route path={"/privacy/kr"} element={<PrivacyPolicy />}/>
          <Route path={"/privacy/en"} element={<PrivacyPolicyEN />}/>
          <Route path={"/privacy/en/california"} element={<PrivacyCalifornia />}/>
          <Route path={"/privacy/en/user-rights"} element={<UserRights />}/>
          <Route path={"/privacy/jp"} element={<PrivacyPolicyJP />}/>
          {/*service policy*/}
          <Route path={"/service/kr"} element={<ServicePolicy />}/>
          <Route path={"/service/en"} element={<ServicePolicyEN />}/>
          <Route path={"/service/jp"} element={<ServicePolicyJP />}/>
      </Routes>
  );
}

export default App;
