import styled from "styled-components";
import "../styles/term.css"

const PrivacyCalifornia = () => {
    return (
        <Wrapper>
            <TextWrap>
                <p>Your California Privacy Rights</p>
                    <p>
                    Right to Opt-Out : We use and share your personal information only in accordance with this privacy policy. We do not believe this use and sharing is a sale under the CCPA. We have not sold your personal information in the last 12 months and we currently do not have any plans to sell your personal information in the future.
                    </p>
                    <p>
                        Do Not Track : CCPA requires us to inform you how we respond to web browser Do Not Track(DNT) signals. Because there is no industry or legal standard for recognizing or honoring DNT signals, we do not respond to DNT signals at this time.
                    </p>
                    <p>
                        Exercising your rights : If you want to exercise your California privacy rights or if you have any questions regarding our practices, please e-mail us(dosa_help@mammothplay.io).
                    </p>
            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;
`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
export default PrivacyCalifornia