import React, { useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const MobileImages = () => {
  useEffect(() => {
    const hide = (item) => {
      gsap.set(item, { autoAlpha: 0 });
    };

    const animate = (item) => {
      let delay = item.dataset.delay;

      gsap.fromTo(
        item,
        { autoAlpha: 0, x: 0, y: 200 },
        { autoAlpha: 1, x: 0, y: 0, delay: delay, duration: 3, overwrite: "auto", ease: "expo" }
      );
    };

    gsap.utils.toArray(".reveal").forEach((item) => {
      hide(item);

      ScrollTrigger.create({
        trigger: item,
        start: "top bottom",
        end: "bottom top",
        markers: false,
        onEnter: () => {
          animate(item);
        },
      });
    });
  }, []);
  return (
    <Wrapper>
      <Section id="firstSection">
        <FirstImage className="reveal"></FirstImage>
      </Section>
      <SecondSection id="secondSection">
        <SecondImage className="reveal"></SecondImage>
      </SecondSection>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
`;
const Section = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgb(214, 247, 219);
  background: linear-gradient(180deg, rgba(214, 247, 219, 1) 0%, rgba(109, 214, 200, 1) 50%);
`;
const SecondSection = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgb(109, 214, 200);
  background: linear-gradient(180deg, rgba(109, 214, 200, 1) 0%, rgba(70, 215, 255, 1) 50%);
`;
const FirstImage = styled.div`
  width: 90%;
  height: 70%;
  background: url("/assets/images/img_01.png") no-repeat;
  background-size: contain;
  background-position: top;
  -webkit-border-radius: 4%;
  -moz-border-radius: 4%;
  border-radius: 4%;
  border: none;
`;
const SecondImage = styled.div`
  width: 90%;
  height: 70%;
  background: url("/assets/images/img_02.png") no-repeat;
  background-size: contain;
  background-position: top;
  -webkit-border-radius: 4%;
  -moz-border-radius: 4%;
  border-radius: 4%;
  border: none;
`;
export default MobileImages;
