import styled from "styled-components";
import "../styles/term.css"

const UserRights = () => {
    return (
        <Wrapper>
            <TextWrap>
                <h1 id="-user-s-rights-"><strong>User’s Rights</strong></h1>
                <p>Users may request verification of how their personal data is collected, used, provided, and destroyed
                    through Mammoth Play&#39;s Privacy Policy at any time. Furthermore, users may request the
                    rectification, erasure, or limitation of their personal data provided by Mammoth Play directly or
                    through the platform provider, and Mammoth Play shall endeavor to grant this request without delay
                    unless the data must be maintained for legitimate business or legal purposes. Moreover, so long as
                    the user&#39;s request does not require undue effort, Mammoth Play shall grant the request for free
                    and may request verification of the user&#39;s identity prior to updating or executing the
                    request.</p>
                <p>Mammoth Play has no authority to process data provided by users to platform providers (Google).
                    Therefore, if data provided to platform providers must be processed, Mammoth Play shall inform that
                    user that it has no authority for such and that the user may request this right from the platform
                    provider as below.</p>
                <p><strong>1.Right of access</strong></p>
                <p>Mammoth Play collects user data as follows.
                    <LinkText href={"/privacy/en"}>
                        Click here for more information.
                    </LinkText>
                    </p>
                <ul>
                    <li>Device information, log information, and purchase information is collected during service use.
                    </li>
                    <li>Mammoth Play cannot identify anyone using the service via a guest account. Therefore, should
                        guest account users fail to provide additional identifying information, Mammoth Play may be
                        unable to accede to some requests in below to guest account users.
                    </li>
                </ul>

                <p><strong>2.Right to rectification</strong></p>
                <p>Use the following links to request the rectification of user data.</p>
                <ul>
                    <li>Google account users:
                        <LinkText href={"https://myaccount.google.com/connections"}>
                        click here
                        </LinkText>
                    </li>
                    <li>Apple account users:
                        <LinkText href={"https://appleid.apple.com/account/manage"}>
                        click here
                        </LinkText>
                    </li>
                </ul>

                <p><strong>3.Right to erasure</strong></p>
                <p>Request erasure of user data as follows.</p>
                <ul>
                    <li>Should a user delete his/her Google account or request the erasure of data collected during
                        service use such as usage time, the record of use, and record of item use, all items, levels,
                        etc. will be deleted and the service may no longer be used. Users who wish to delete may request
                        to &quot;Delete&quot; in Game Settings.
                    </li>
                    <li>Users who wish to delete their records of service use may do so in &quot;Game
                        Settings&quot; ⇒ &quot;Delete Account&quot;.
                    </li>
                </ul>

                <p><strong>4.Right to restriction of processing or right to object</strong></p>
                <p>Use the following methods to request limitation or object to processing of user data.</p>
                <ul>
                    <li>Google account users: disable single sign-on (SSO) in Game Settings and then reset the scope of
                        app data shared with platform providers (Google). Reset which data is shared as follows. Disable
                        SSO in &quot;Game Settings&quot; ⇒ &quot;Disconnect&quot;.
                        <ul>
                            <li>Google account users:
                                <LinkText href={"https://myaccount.google.com/u/0/permissions"}>
                                    click here
                                </LinkText>
                            </li>
                            <li>Apple account users:
                                <LinkText href={"https://support.apple.com/"}>
                                    click here
                                </LinkText>
                            </li>
                        </ul>
                    </li>
                    <li>Google account users: disable single sign-on (SSO).</li>
                    <li>Should a user request limitation or object to the processing of collected device information,
                        log information, and item purchase information while using the service, Mammoth Play will be
                        unable to provide the service or enable its use and the user will be unable to use the service
                        during the period of limitation. Click here to request the limitation of user data processing or
                        object to user data processing completely
                    </li>
                </ul>

                <p><strong>5.Right to Automated individual decision-making (including profiling)</strong></p>
                <p>Mammoth Play does not use an automated profiling system for user data to analyze or predict
                    preferences or interests. Users who do not wish to receive Push notifications may disable them
                    in &quot;Game Settings&quot; ⇒ &quot;Notifications-off&quot;.</p>

                <p><strong>6.Right to data portability</strong></p>
                <p>Should a user request transfer of his/her own personal data, Mammoth Play shall provide the following
                    user-requested data to the user or, if technically possible, to the requested controller without
                    undue delay in a structured, commonly used, and machine-readable format. However, if this transfer
                    is expected to take more than 1 month due to multiple or complicated requests for information,
                    Mammoth Play shall, within that 1 month, notify the user of the reason for the delay and delay time
                    (up to 2 months) before providing the information. Should Mammoth Play fail to provide the requested
                    data within the relevant time, the applicant may request judicial assistance from a supervisory
                    body.</p>
                <p>The user may request the transfer of the following data.</p>
                <ul>
                    <li>Transfer of Apple or Google account data must be requested
                        from&nbsp;
                        <LinkText href={"https://support.apple.com/"}>
                            Apple
                        </LinkText>
                        &nbsp;or&nbsp;
                        <LinkText href={"https://google.com/"}>
                            Google
                        </LinkText> respectively.
                    </li>
                    <li>Click here to request the transfer of device, log, or item purchase information collected by
                        Mammoth Play while using the service.
                    </li>
                    <li>Requests for the transfer of in-app purchase records must be directed to&nbsp;
                        <LinkText href={"https://support.apple.com/"}>
                            Apple,
                        </LinkText>
                        &nbsp;or&nbsp;
                        <LinkText href={"https://payments.google.com/payments/u/0/home"}>
                            Google
                        </LinkText>.
                    </li>
                </ul>
                <p><strong>7.Notification of a personal data breach</strong></p>
                <p>Should Mammoth Play become aware of a serious threat to user rights or freedom due to breach of
                    users&#39; personal data or the possibility of such, it shall notify the user of the breach without
                    undue delay while specifying the following information and then notify a supervisory body of the
                    breach within 3 days (72 hours).</p>
                <ul>
                    <li>Personal data protection officer with name and contact information</li>
                    <li>Potential results of the data breach</li>
                    <li>Explanation of actions to take to alleviate negative effects due to the data breach</li>
                </ul>
                <p><strong>8.Help Center &amp; Representative</strong></p>
                <p>[Help Center]</p>
                <ul>
                    <li>Address: 4F, 26, Dosan-daero 28-gil, Gangnam-gu, Seoul, Republic of Korea</li>
                    <li>E-mail: dosa_help@mammothplay.com</li>
                </ul>

            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;
`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const LinkText = styled.a`
    color: blue;
    font-weight: 700;
    cursor: pointer;
`
export default UserRights