import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import styled from "styled-components";

const AnimationText = () => {
  useEffect(() => {
    const hide = (item) => {
      gsap.set(item, { autoAlpha: 0 });
    };

    const animate = (item) => {
      let delay = item.dataset.delay;

      gsap.fromTo(
        item,
        { autoAlpha: 0, x: 0, y: 200 },
        {
          autoAlpha: 1,
          x: 0,
          y: 0,
          delay: delay,
          duration: 2,
          overwrite: "auto",
          ease: "expo",
        }
      );
    };

    gsap.utils.toArray(".reveal").forEach((item) => {
      hide(item);

      ScrollTrigger.create({
        trigger: item,
        start: "top bottom",
        end: "bottom top",
        markers: false,
        onEnter: () => {
          animate(item);
        },
      });
    });
  }, []);

  return (
    <Wrapper className="pageBox">
      <DosaLogo
        className="reveal"
        src="/assets/images/commom_ico_dosa_logo_ver2.png"
        alt="fade-logo"
      />
      <AboutText className="reveal">
        {
          "It's a hack-and-slash rogue-like game with a mysterious world adapted from world famous fairy tales, where a trans-ported taoist survives with heroes."
        }
      </AboutText>
      <AboutChannels className="reveal">
        <a
          href="https://game.naver.com/lounge/DOSA_Survivor/"
          target="_blank"
          rel="noreferrer"
        >
          <AboutChannelsImage src="/assets/images/naver.png" />
        </a>
        <a
          href="https://discord.gg/xVgBw9YJaK"
          target="_blank"
          rel="noreferrer"
        >
          <AboutChannelsImage src="/assets/images/discord.png" />
        </a>
      </AboutChannels>
      {/*<WhitePaperBtn*/}
      {/*  className="reveal"*/}
      {/*  onClick={() => {*/}
      {/*    window.open("https://project-m-white-paper-v230719.gitbook.io/dosa-survivor-in-wonderland-1/");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <BtnText>White Paper</BtnText>*/}
      {/*  <BtnArrow src="/assets/images/common_ico_arrow_right_blue.png" alt="arrow-icon" />*/}
      {/*</WhitePaperBtn>*/}
    </Wrapper>
  );
};
// const bounce = keyframes`
//   0%, 100% {
//       transform: translateX(0) ;
//     }
//    50%  {
//       transform: translateX(20%);
//     }
// `;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      180deg,
      rgba(28, 153, 181, 1) 0%,
      rgba(223, 254, 241, 0.7) 80%,
      rgba(214, 247, 219, 1) 100%
    ),
    url("/assets/images/img_02.png") no-repeat;
  background-position: top 25% left 20%;
  background-size: 130%;
  @media screen and (max-width: 1080px) {
    background-size: 200%;
  }
  @media screen and (max-width: 700px) {
    background-size: 300%;
  }
  @media screen and (max-width: 500px) {
    height: 90vh;
    background-size: 400%;
  }
`;
const DosaLogo = styled.img`
  width: 28rem;
  padding: 14rem 0 2rem 0;
  filter: drop-shadow(5px 10px 10px #9debff);
  @media screen and (max-width: 820px) {
    width: 20rem;
    padding: 8rem 0 2rem 0;
  }
  @media screen and (max-width: 500px) {
    width: 17rem;
  }
`;
const AboutText = styled.div`
  width: 45%;
  font-family: "Pretendard";
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  color: white;
  text-align: center;
  padding-bottom: 5rem;
  text-shadow: 0px 4px 60px rgba(0, 0, 0, 50%);
  @media screen and (max-width: 820px) {
    width: 60%;
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 5rem;
  }
  @media screen and (max-width: 500px) {
    width: 80%;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    line-height: 26px;
  }
`;
const AboutChannels = styled.div`
  width: 30%;
  font-family: "Pretendard";
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-shadow: 0px 4px 60px rgba(0, 0, 0, 50%);
  @media screen and (max-width: 820px) {
    width: 60%;
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 5rem;
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    width: 80%;
    font-size: 1.2rem;
    padding-bottom: 4rem;
    line-height: 26px;
  }
`;
const AboutChannelsImage = styled.img`
  width: 80%;
  @media screen and (max-width: 820px) {
    width: 60%;
  }
  @media screen and (max-width: 500px) {
    width: 60%;
  }
`;
// const WhitePaperBtn = styled.div`
//   cursor: pointer;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 1.5rem;
//   width: 14rem;
//   height: 4rem;
//   border-radius: 20rem;
//   background-color: rgba(255, 255, 255, 0.75);
//   @media screen and (max-width: 820px) {
//     width: 12rem;
//     height: 4rem;
//   }
//   @media screen and (max-width: 500px) {
//     width: 10rem;
//     font-size: 1.2rem;
//     padding: 0 1.6rem;
//     height: 3.4rem;
//   }
// `;
// const BtnText = styled.div`
//   font-family: "Pretendard";
//   font-weight: 700;
//   font-size: 1.25rem;
//   text-align: left;
//   color: #2ca2bb;
//   @media screen and (max-width: 500px) {
//     font-size: 1.25rem;
//   }
// `;
// const BtnArrow = styled.img`
//   animation: ${bounce} 1.2s infinite;
//   width: 30px;
//   @media screen and (max-width: 500px) {
//     width: 20px;
//   }
// `;
export default AnimationText;
