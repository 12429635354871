// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/common_ico_arrow_right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
div:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.swiper-button-next {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: 40px 40px;
  background-position: center;
  padding-left: 4rem;
  padding-top: 50vh;
}

.swiper-button-prev {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: 40px 40px;
  background-position: center;
  transform: scaleX(-1);
  padding-left: 4rem;
  padding-top: 50vh;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.swiper-button-prev:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`, "",{"version":3,"sources":["webpack://./src/styles/swiper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6CAA6C;AAC/C;AACA;EACE,aAAa;EACb,6CAA6C;AAC/C;AACA;EACE,6DAA6E;EAC7E,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,6DAA6E;EAC7E,0BAA0B;EAC1B,2BAA2B;EAC3B,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;AACf;AACA;EACE,aAAa;EACb,6CAA6C;AAC/C;AACA;EACE,aAAa;EACb,6CAA6C;AAC/C","sourcesContent":["input:focus {\n  outline: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\ndiv:focus {\n  outline: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n.swiper-button-next {\n  background: url(\"/public/assets/images/common_ico_arrow_right.png\") no-repeat;\n  background-size: 40px 40px;\n  background-position: center;\n  padding-left: 4rem;\n  padding-top: 50vh;\n}\n\n.swiper-button-prev {\n  background: url(\"/public/assets/images/common_ico_arrow_right.png\") no-repeat;\n  background-size: 40px 40px;\n  background-position: center;\n  transform: scaleX(-1);\n  padding-left: 4rem;\n  padding-top: 50vh;\n}\n\n.swiper-button-next::after,\n.swiper-button-prev::after {\n  display: none;\n}\n.swiper-button-next:focus {\n  outline: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n.swiper-button-prev:focus {\n  outline: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
