import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { styled } from "styled-components";

const SecondImageScale = () => {
  useEffect(() => {
    const ani9 = gsap.timeline();
    ani9.to("#secondSection .parallax__item__img", { scale: 1.6, height: "100vh" });
    ScrollTrigger.create({
      animation: ani9,
      trigger: "#secondSection",
      start: "top",
      end: "bottom center",
      scrub: true,
      pin: true,
      anticipatePin: 1,
    });
  }, []);

  return (
    <Section id="secondSection" className="parallax__item">
      <ThirdParts src="/assets/images/common_ico_ object_03.png" alt="page-parts-3" />
      <ImageWrapper>
        <ScaledImage className="parallax__item__img"></ScaledImage>
      </ImageWrapper>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  height: 150vh;
  position: relative;
  display: flex;
  justify-content: center;
  /* background: rgb(109, 214, 200);
  background: linear-gradient(180deg, rgba(109, 214, 200, 1) 0%, rgba(70, 215, 255, 1) 50%); */
`;
const ImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const ScaledImage = styled.div`
  width: 100%;
  height: 80%;
  background: url("/assets/images/img_02.png") no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-border-radius: 4%;
  -moz-border-radius: 4%;
  border-radius: 4%;
  border: none;
`;
// const SecondParts = styled.img`
//   position: absolute;
//   top: 0%;
//   right: 5%;
//   width: 10rem;
//   @media screen and (max-width: 1400px) {
//     position: absolute;
//     width: 7rem;
//     right: 2%;
//   }
//   @media screen and (max-width: 1200px) {
//     position: absolute;
//     top: -2%;
//   }
//   @media screen and (max-width: 820px) {
//     display: none;
//   }
// `;
const ThirdParts = styled.img`
  position: absolute;
  top: 50%;
  left: 5%;
  width: 10em;
  @media screen and (max-width: 1400px) {
    width: 7rem;
    top: 55%;
    left: 2%;
  }
  @media screen and (max-width: 1200px) {
    top: 60%;
    left: 2%;
  }
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
export default SecondImageScale;
