import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { styled } from "styled-components";

const ImageScale = () => {
  useEffect(() => {
    const ani9 = gsap.timeline();
    ani9.to("#firstSection .parallax__item__img", { scale: 1.5, height: "100vh" });
    ScrollTrigger.create({
      animation: ani9,
      trigger: "#firstSection",
      start: "top",
      end: "bottom center",
      scrub: true,
      pin: true,
      anticipatePin: 1,
    });
  }, []);

  return (
    <Section id="firstSection" className="parallax__item">
      <ImageWrapper>
        <ScaledImage className="parallax__item__img"></ScaledImage>
      </ImageWrapper>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  height: 150vh;
  position: relative;
  display: flex;
  justify-content: center;
  /* background: rgb(214, 247, 219); */
  /* background: linear-gradient(180deg, rgba(214, 247, 219, 1) 0%, rgba(109, 214, 200, 1) 50%); */
`;
const ImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const ScaledImage = styled.div`
  width: 100%;
  height: 80%;
  background: url("/assets/images/img_01.png") no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-border-radius: 4%;
  -moz-border-radius: 4%;
  border-radius: 4%;
  border: none;
`;
export default ImageScale;
