import styled from "styled-components";
import "../styles/term.css"
const PrivacyPolicyJP = () => {
    return (
        <Wrapper>
            <SelectWrap>
                <SelectBox onChange={(e)=>{
                    let lang = e.target.value
                    window.location.replace(`https://dosasurvivor.com/privacy/${lang}`)
                }}>
                    <SelectLanguage value={"kr"}>한국어</SelectLanguage>
                    <SelectLanguage value={"en"}>English</SelectLanguage>
                    <SelectLanguage value={"jp"} selected={true}>日本語</SelectLanguage>
                </SelectBox>
            </SelectWrap>
            <TextWrap>
                <h1 id="-2024-04-30-"><strong>プライバシーポリシー</strong></h1>
                <p>本プライバシーポリシーは2024年4月30日から有効となります。</p>
                <p>本プライバシーポリシーは、Mammoth Play
                    Corporation（以下、「当社」という。）が提供するサービス（以下、「サービス」という。）を利用するお客様より取得する、お客様情報の取扱いに関して規定します。当社は、お客様情報の保護に対する重要性を認識し、本プライバシーポリシー及び関連法令に基づき、お客様情報の保護に最善の努力を尽くします。
                </p>
                <p><strong>1. 適用範囲</strong></p>
                <p>本プライバシーポリシーは、当社が提供するサービスにのみ適用され、第三者がサービスに掲載する広告などには本プライバシーポリシーは適用されません。

                </p>
                <p><strong>2. 取得するお客様情報</strong></p>
                <p>当社は、サービスを提供する上で下記のお客様情報を取得・利用し、お客様は当社がこれら情報を取得・利用することに対して同意します。
                </p>
                <p><strong>1) お客様に関する情報</strong></p>
                <p>
                    当社は、お客様がプラットフォーム事業者やアプリストア事業者(Line、Facebook、Google、Appleなど)のサービス(以下、「外部サービス」という。)で使用する利用者アカウント情報(ニックネームを含む)、プロフィール情報、友だち情報等(以下、「外部サービスアカウント情報」という。)を外部サービス事業者が提供する方式に基づき取得します。
                </p>

                <p><strong>2) サービス利用及び決済に関する情報</strong></p>
                <p>
                    当社は、お客様がサービスを利用する場合、サービスにアクセスした時間、IPアドレス、年齢、利用履歴(不正利用履歴を含む)などを取得することができます。また、サービスで利用するコンテンツに関する情報(コンテンツの種類、価格、購入時間、決済手段、注文番号など)を取得することができます。
                </p>

                <p><strong>3) 端末情報</strong></p>
                <p>
                    当社は、お客様が利用する携帯、タブレット等の端末でサービスを利用する場合、端末の機種及び識別情報、OS情報、広告識別者情報(広告ID)、ロケール情報、モバイルネットワーク情報(電話番号を除く)、国家情報などを取得することができ、取得した広告識別者情報を、本プライバシーポリシーに基づき当社が取得するお客様情報とつなげることができます。
                </p>

                <p><strong>4) お客様対応の過程で取得する情報</strong></p>
                <p>
                    当社は、お客様がサービスに関するお問い合わせを送信する過程で、メールアドレス、端末機種情報、OS情報、電話番号、コンテンツ購入に使用した外部サービスアカウント情報など、お客様のお問い合わせ対応のために必要な情報を取得します。
                </p>

                <p><strong>5) キャンペーンやプロモーションにより取得する情報</strong></p>
                <p>
                    当社は、サービスでキャンペーンやプロモーションを実施する場合、お客様よりお名前、生年月日、住所、電話番号、メールアドレス等、キャンペーンやプロモーション参加に必要な情報を取得することができます。
                </p>


                <p><strong>3. お客様情報取得方法</strong></p>
                <p>当社は、お客様情報を下記の方法で取得します。</p>
                <p>
                    当社は、お客様がサービスを利用する場合、サービスに関するお問い合わせを送信する場合、あるいはキャンペーンやプロモーションに参加する場合、直接お客様の情報を取得し、お客様が外部サービスで使用する外部サービスアカウント情報を外部サービス事業者が提供する方法に基づき取得します。
                </p>

                <p><strong>4. 取得した情報の利用目的</strong></p>
                <p>当社は、取得したお客様情報を下記の目的で利用します。</p>
                <p>1) サービスの提供・改善・開発を目的とする</p>
                <ul>
                    <li>お客様が円滑にサービスを利用するため</li>
                    <li>サービス利用に関する統計データを作成し、これらをサービス改善に使用するため</li>
                    <li>お客様より送られたお問い合わせを対応するため</li>
                    <li>現在提供するサービスの改善または新規サービス開発及び提供のため</li>
                    <li>キャンペーンやプロモーション等による景品提供のため</li>
                    <li>その他サービスに関する告知・お知らせを提供するため</li>
                </ul>
                <p> 2) サービス不正利用防止など、防止を目的とする</p>
                <ul>
                    <li>不正アクセスなど、不正利用防止のため</li>
                    <li>ハッキング、不正利用等が生じた場合、本人確認のため</li>
                </ul>
                <p>3) 広告発信及びパーソナライズ化を目的とする</p>
                <ul>
                    <li>サービスに関する情報または当社以外の事業者が提供する広告などを提供するため</li>
                    <li>お客様個人に対してパーソナライズ化されたサービスを提供するため</li>
                </ul>

                <p><strong>5. 第三者提供及び委託</strong></p>
                <p>1) 第三者への提供</p>
                <p>
                    当社は、お客様が同意または法令に基づく場合、「4. 取得した情報の利用目的」で定めた場合、下記で定めた場合を除き、第三者にお客様情報を提供しません。
                </p>
                <p>
                    当社は、お客様に第三者の商品・サービス及びその他商品・サービスに関する広告を提供することを目的に、第三者にお客様情報を提供しません。ただし、モバイル環境で広告ネットワーク社(AdMob、Facebook
                    Audience Network、Unity
                    Adsなどのサービスを想定するがこれに限らない。)を利用して広告を発信する場合、お客様のOS情報、端末識別情報など、お客様を識別できない情報を広告発信目的で該当広告ネットワーク社に提供します。

                </p>
                <p>
                    また、当社はサービス分析、改善などを目的に、第三者が提供する解析ツール(Google
                    Analytics、Appsflyerなどを想定するがこれに限らない。)を利用するとき、当社の設定及び解析ツールの仕様に応じて取得したお客様を識別できない情報を当該第三者に提供する場合があり、第三者が提供する解析ツールは第三者が取得した情報を利用し、これら解析ツールで第三者が取得した情報は本プライバシーポリシーが適用されません。

                </p>
                <p>2) 共同利用</p>
                <p>
                    当社は、利用目的達成に必要な範囲内で、お客様情報を当社の子会社または関連会社に共同で利用します。共同で利用されるお客様情報は、取得するお客様情報で定めた情報と同じであり、共同利用目的は取得した情報の利用目的で定めた目的と同じです。共同利用の管理責任は当社にあり、関連するお問い合わせについてはサポートセンターまでお問い合わせください。
                </p>
                <p>3) 委託</p>
                <p>
                    当社は、利用目的の達成のために必要な範囲内でお客様情報の全部または一部を委託することができます。この場合、当社は委託業者と別途契約を締結し、委託業者に対する法律上または契約上の必要な監督と適正な管理システムを設けて管理します。
                </p>
                <p><strong>6. お客様の選択権</strong></p>
                <p>
                    お客様は、以下の方法でお客様情報の修正、利用および第三者提供について管理することができます。
                </p>
                <p>1) お客様情報の閲覧と編集</p>
                <p>
                    お客様は、サービスの環境設定で、外部サービス事業者が当社に提供した外部サービスのアカウント情報を閲覧することができ、サービスの環境設定から脱退・アカウント削除を利用して、外部サービスのアカウント情報の収集及び利用の同意を撤回（会社が収集したお客様情報を含む）を行うことができます。お客様の外部サービスのアカウント情報の修正は、外部サービスから提供される機能を利用して修正しなければならず、外部サービスのアカウント情報のエラーの修正を希望する場合は、変更が完了するまで、外部サービスのアカウント情報を利用または提供しません。
                </p>
                <p>2) 観覧設定</p>
                <p>
                    お客様は、ご利用中の端末またはサービスの環境設定の通知設定にて通知の設定を変更することができます。通知設定の方法は、ご利用中の端末やサービスに応じて異なるため、ご利用の端末または環境設定でご確認ください。
                </p>
                <p>
                    当社はお客様が通知を未受信に設定した場合でも、お客様に重要な通知などを未受信の設定問わず、通知を送信できるものとします。
                </p>
                <p>3) 広告受信拒否</p>
                <p>
                    お客様はサービスに掲載される広告に対して、ご利用中の端末で提供する設定機能を使用してこれらを遮断したり、広告ネットワーク社が提供する方法で広告の受信を拒否することができます。
                </p>

                <p><strong> 7. 脱退者情報</strong></p>
                <p>
                    当社は、お客様がサービスの設定よりアカウント削除や脱退を利用してサービスを脱退する場合、関連法令及び社内規定で定めた内容に基づき、お客様情報を削除します。
                </p>
                <p><strong>8. お客様情報の安全保護</strong></p>
                <p>
                    当社は、お客様情報に対する不法的な接近、変更、流出等を防止するために、物理的なセキュリティシステムの導入、内部管理システムの確立などの必要な措置を通じ、お客様情報を厳密に扱います。また、アクセス権限のある従業員の数は、会社の方針に基づいて必要最小限の従業員に限定し、特に、関連する業務を行う従業員については、定期的な教育を実施することにより、お客様情報の厳格な管理が行われるようにします。
                </p>
                <p><strong>9. お客様サポート</strong></p>
                <p>
                    当社は、お客様情報を保護し安全対策を講じするために最善を尽くします。プライバシーポリシーに関するご質問や詳細情報をご希望の場合は、お客様サポートセンターまたは下記担当者までお問い合わせください。
                </p>
                <p><strong>[お客様サポートセンター]</strong></p>
                <p>
                    - 住所： 4F, 26, Dosan-daero 28-gil, Gangnam-gu, Seoul, Republic of Korea
                    <br/>
                    - メールアドレス：dosa_cs@mammothplay.io
                </p>
                <br />
                <p><strong>10. 改定</strong></p>
                <p>
                    当社は、当社が必要と判断する場合、サービス提供に必要な範囲内でプライバシーポリシーを変更することができます。この場合、当社は、変更前に変更される規約の内容及び効力発生日等をお客様が認識できるよう、当社が定めた方法で告知します。変更後のプライバシーポリシーは、効力発生日から効力が生じるものとします.
                </p>
            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;
`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectWrap = styled.div`
    width: 60%;
    text-align: end;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectBox = styled.select`
    padding: 0.25rem;
    border: 1px solid black;
`
const SelectLanguage = styled.option``

export default PrivacyPolicyJP