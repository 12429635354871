import Table from "./Table";
import styled from "styled-components";

const ServicePolicy = ()=>{
return(
    <Wrapper>
        <SelectWrap>
            <SelectBox onChange={(e)=>{
                let lang = e.target.value
                window.location.replace(`https://dosasurvivor.com/service/${lang}`)
            }}>
                <SelectLanguage value={"kr"}>한국어</SelectLanguage>
                <SelectLanguage value={"en"}>English</SelectLanguage>
                <SelectLanguage value={"jp"}>日本語</SelectLanguage>
            </SelectBox>
        </SelectWrap>
        <TextWrap>
            <h1 id="-"><strong>운영정책</strong></h1>
            <h2 id="-1-"><strong>1. 공통 원칙</strong></h2>
            <ul>
                <li>이용자의 서비스 이용제한조치는 이용자의 행위 유형과 이용자 계정별로 이용제한기간이 적용되며, 동일한 이용자 계정을 사용하는 모든 서비스에 대해 이용이 제한됩니다.
                </li>
                <li>회사는 고객센터 및 각종 기관에서 접수된 민원 내용, 경찰 수사 내용, 운영자의 모니터링을 통해 정황을 확인한 후 제재대상행위를 한 것으로 추정되는 이용자에 대하여 이용제한을 할
                    수 있습니다.
                </li>
                <li>회사는 이용자의 행위가 제재대상행위에 구체적으로 해당되지 않는 행위라 하더라도, 서비스 약관을 위반하는 행위에 해당되는 경우 본 서비스 이용정책에 따라 서비스 이용을 제한할 수
                    있습니다.
                </li>
                <li>회사는 이용자가 제재대상행위에 해당되는 행위 내지 방법으로 게임머니, 경험치, 포인트, 아이템 등(이하 “콘텐츠”라 합니다)을 생성∙획득한 경우에는 서비스 이용제한과 별도로
                    생성∙획득한 콘텐츠를 회수하거나 초기화할 수 있습니다. 또한, 회사는 이용자가 제재대상행위에 해당하는 행위 내지 방법으로 생성∙획득된 콘텐츠를 받는 등 객관적으로 불법행위 가담이
                    의심되는 경우에도 서비스 이용제한과 별도로 생성∙획득한 콘텐츠를 회수하거나 초기화할 수 있습니다.
                </li>
                <li>이용자의 행위 내지 방법 확인 후 서비스 이용제한이 진행되므로 행위시점과 이용제한시점이 상이할 수 있으며, 서비스 이용제한기간 중 탈퇴를 신청하는 경우 이용제한기간 종료 시까지
                    서비스 이용이 제한되거나 거부될 수 있습니다.
                </li>
                <li>객관적인 증거가 불충분하거나, 해당 이용자 계정이 이미 제한∙삭제된 경우 이용제한조치가 이루어지지 않습니다. 다만, 조사를 통해 더 심각한 제한사유에 해당하는 행위나
                    반복적∙상습적인 위반행위가 추가 발견되는 경우 이미 제한 중이라도 가중 처리될 수 있습니다.
                </li>
            </ul>
            <br />
            <h2 id="-2-"><strong>2. 이용제한조치</strong></h2>
            <Table/>
        </TextWrap>
    </Wrapper>

)
}
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100vw;
            padding: 3rem 0;
            background-color: white;
            color: black;

            `
            const TextWrap = styled.div`
            width: 60%;
            @media screen and (max-width: 1240px) {
            width: 90%;
        }
            `
            const SelectWrap = styled.div`
            width: 60%;
            text-align: end;
            @media screen and (max-width: 1240px) {
            width: 90%;
        }
            `
            const SelectBox = styled.select`
            padding: 0.25rem;
            border: 1px solid black;
            `
            const SelectLanguage = styled.option``

export default ServicePolicy