import ScrollVideo from "./ScrollVideo";
import AnimationText from "./AnimationText";
import AnimationImage from "./AnimationImage";
import SectionRoadMap from "./SectionRoadmap";
import {useEffect} from "react";
import {styled} from "styled-components";

const Contents = ()=>{

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

return (
    <Wrapper>
        <ScrollVideo />
        <AnimationText />
        <AnimationImage />
        <SectionRoadMap />
    </Wrapper>
)}

const Wrapper = styled.div`
  overflow: hidden;
  background-color: #1c1c1c;
`;

export default Contents