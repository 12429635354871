import styled from "styled-components";
import "../styles/term.css";

const TermsOfServiceEN = () => {
  return (
    <Wrapper>
      <SelectWrap>
        <SelectBox
          onChange={(e) => {
            let lang = e.target.value;
            window.location.replace(`https://dosasurvivor.com/terms/${lang}`);
          }}
        >
          <SelectLanguage value={"kr"}>한국어</SelectLanguage>
          <SelectLanguage value={"en"} selected={true}>
            English
          </SelectLanguage>
          <SelectLanguage value={"jp"}>日本語</SelectLanguage>
        </SelectBox>
      </SelectWrap>
      <TextWrap>
        <h1 id="-terms-of-service-">
          <strong>Terms of Service</strong>
        </h1>
        <p>Effective Date: 09. 05. 2024</p>
        <p>
          These Terms of Service apply to services provided to users by Mammoth
          Play. Therefore, users must be sure to pay close attention while
          reading and must periodically check for changes that may occur
          according to Article 1.3. Furthermore, Mammoth Play may request
          consent to collect additional information while users are using the
          service, and this additional consent shall constitute part of these
          Terms and Conditions.
        </p>
        <p>
          <strong>1. Using the Service</strong>
        </p>
        <p>
          1.1. Users may use the application after installing and running it and
          agreeing to these Terms and Conditions and the Privacy Policy. This
          also applies when the user provides personal information to the
          platform provider and/or social media provider to use the service.
        </p>
        <p>
          1.2. If using the service via a guest account (ID), all records
          including records of service usage and unused content will be deleted
          if the device is changed or the application deleted. Users who wish to
          continue using the service without having their record of service
          deleted should use a play account or social medial account and single
          sign-on (SSO).
        </p>
        <p>
          1.3. Users must use the service according to the Terms of Service and
          Privacy Policy/Service Policy (collectively referred to as
          &quot;Service Policy&quot; below). Furthermore, use of the service via
          methods other than those defined in the Service Policy is prohibited,
          and the service may only be used within the scope permitted by law.
          Should users fail to comply with the Service Policy or come under
          investigation due to suspicion of cheating, service provision may be
          temporarily restricted or stopped.
        </p>
        <p>
          1.4. Mammoth Play will publish these Terms and Conditions for user
          perusal on its website or notify users of them through its
          application. Users must periodically check these Terms and Conditions.
          Mammoth Play may alter these Terms and Conditions should the service
          or relevant laws change. The updated Terms and Conditions shall take
          effect 30 days after the changes are announced. Should users not
          consent to the updated Terms and Conditions, their use of the service
          may be suspended. Should they continue using the service, they will be
          considered to have given consent to the updated Terms and Conditions.
        </p>
        <p>
          <strong>2. Account Management</strong>
        </p>
        <p>
          2.1. Mammoth Play conducts user management, including whether or not
          they can use the service, via guest accounts, play accounts, and
          social media accounts (&quot;user accounts&quot;, below)
        </p>
        <p>
          2.2. Users bear sole responsibility to care for and manage their own
          user accounts, and are fully responsible for careless management of
          their own user accounts as well as damage due to their use by a third
          party.
        </p>
        <p>
          2.3. Users may use the service with their own guest account, play
          account, or social media account or through a single sign-on with an
          account other than a guest account.
        </p>
        <p>
          <strong>3. Privacy Protection</strong>
        </p>
        <p>
          3.1. Mammoth Play shall make efforts to protect the personal
          information of users, including user accounts, and the protection of
          personal information shall be in accordance with Mammoth Play Privacy
          Policy.
        </p>
        <p>
          3.2. Mammoth Play may request additional information from users other
          than user data received through the platform provider or/and social
          media provider and shall not use this collected or received personal
          information for any purpose other than specified in the Privacy
          Policy. Please read the Privacy Policy for more information.
        </p>
        <p>
          3.3. Mammoth Play does not collect any personal information on minors
          under 13 years of age (16 years of age in EU member nations).
        </p>
        <p>
          <strong>
            4. Third-party Advertisements &amp; Push Notifications
          </strong>
        </p>
        <p>
          4.1. Mammoth Play may publish advertisements on the service. Users
          consent to exposure to advertisements while using the service and
          shall not bear any responsibility for loss or damage incurred due to
          advertisement access/participation/trade, etc.
        </p>
        <p>
          4.2. Mammoth Play may use email or push notifications to send
          advertisements, service updates, event details, etc. to users. Users
          may use the feature in the application for declining to receive push
          notifications at any time.
        </p>
        <p>
          <strong>5. Content Purchases</strong>
        </p>
        <p>
          5.1. Users may purchase content according to the payment policy of the
          app store provider, and payment sums may differ due to national
          policies (not limited to taxation policy). Furthermore, the content
          purchase price may be higher depending on the method and policy set by
          the app store provider and payment gateway linked to the app store
          provider, and the payment method also depends on this provider&#39;s
          payment policies.
        </p>
        <p>
          5.2. Content purchased by users may only be used on the device on
          which the application is downloaded and installed.
        </p>
        <p>
          5.3. The usage time for content purchased by users shall be 1 year
          from the date of purchase, and all right to use this content is lost
          after that period has elapsed. However, the stipulated usage period
          for content depends on the usage period set at the time of purchase.
        </p>
        <p>
          5.4. The user may only use content through a user account and may not
          transfer, loan, sell, or exchange it with a third party.
        </p>
        <p>
          <strong>6. In-App Purchases</strong>
        </p>
        <p>
          6.1. In-app payment to purchase content is included in the
          application, and payment methods depend on the app store provider.
        </p>
        <p>
          6.2. Users must use the password feature to prevent unwanted
          third-party in-app purchases, and Mammoth Play shall not bear any
          responsibility for failure to use this in-app purchase protection or
          for in-app purchases occurring due to exposure of the password as a
          result of inattention on the part of users.
        </p>
        <p>
          6.3. Users may cancel purchases within the cooling-off period of 7
          days from the date of content purchase, and refund of purchased
          content shall depend on the refund policy and procedure of the app
          store provider used by the user. Please consult your app store
          provider&#39;s terms of service for its refund policy and application
          procedure.
        </p>
        <p>
          6.4. Unused in-app purchases, content obtained while using the
          service, content obtained through events, etc. (not limited below),
          and all content not acquired through in-app purchases shall not be
          refunded.
        </p>
        <p>
          <strong>7. Suspension &amp; Termination</strong>
        </p>
        <p>
          7.1. Mammoth Play maintains full rights to the service, including
          service operation and termination, and may modify, supplement, or
          terminate all or part of the service if operationally or technically
          necessary to improve the service, patch bugs, etc.
        </p>
        <p>
          7.2. Mammoth Play will provide the service to users as long as there
          are no particular business or technical impediments. However, the
          service may be temporarily suspended for a set period of time if
          necessary for regular system maintenance, server expansion or
          replacement, bug fixes, or changes in the service. In such cases,
          Mammoth Play shall disclose this information and the period to users
          in advance, or shall notify users post facto if prior notification is
          impossible for unavoidable reasons.
        </p>
        <p>
          <strong>8. Disclaimers &amp; Indemnifications</strong>
        </p>
        <p>
          <strong>8.1. Disclaimer of Warranties</strong>
        </p>
        <p>
          THE USER EXPRESSLY ACKNOWLEDGES AND AGREES THAT USE OF THE SERVICE IS
          AT YOUR SOLE RISK AND IS PROVIDED ON AN &quot;AS IS&quot; BASIS
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
          NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        </p>
        <p>
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, NEITHER Mammoth Play
          NOR ANY OF THEIR DIRECTORS, EMPLOYEES, AGENTS, THIRD-PARTY CONTENT
          PROVIDERS, Mammoth Play AND ITS AFFILIATES, LICENSORS, SUPPLIERS,
          ADVERTISERS, SPONSORS, PARTNERS, DISTRIBUTORS, LICENSEES OR LICENSORS
          (COLLECTIVELY, &quot;Mammoth Play PARTIES&quot;) DOES NOT WARRANT THAT
          THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
        </p>
        <p>
          <strong>8.2. Disclaimers of Liability</strong>
        </p>
        <p>
          THE USER ACKNOWLEDGES AND AGREES THAT, TO THE FULLEST EXTENT PERMITTED
          BY ANY APPLICABLE LAW, THE DISCLAIMERS OF LIABILITY CONTAINED HEREIN
          APPLY TO ANY AND ALL DAMAGES OR INJURIES WHATSOEVER CAUSED BY OR
          RELATED TO THE USE OF, OR INABILITY TO USE, THE SERVICE DUE TO ANY
          CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT
          LIMITATION, ACTIONS FOR BREACH OF WARRANTY AND BREACH OF CONTRACT OR
          TORT (INCLUDING NEGLIGENCE), AND THAT Mammoth Play PARTIES SHALL NOT
          BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL, OR
          DATA, IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO
          USE, THE SERVICE.
        </p>
        <p>
          THE USER FURTHER ACKNOWLEDGES AND AGREES THAT Mammoth Play PARTIES ARE
          NOT LIABLE, AND THE USER AGREES NOT TO SEEK TO HOLD Mammoth Play
          PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER
          USERS OF THE SERVICE AND OPERATORS OF EXTERNAL SITES, AND THAT THE
          RISK OF THE SERVICE AND EXTERNAL SITES AND OF INJURY FROM THE
          FOREGOING RESTS ENTIRELY WITH USER.
        </p>
        <p>
          TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, UNDER NO
          CIRCUMSTANCES WILL Mammoth Play PARTIES BE LIABLE TO YOU FOR MORE THAN
          THE AMOUNT YOU HAVE PAID Mammoth Play IN THE ONE HUNDRED AND EIGHTY
          DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST
          ASSERT ANY SUCH CLAIM.
        </p>
        <p>
          <strong>8.3. Indemnification</strong>
        </p>
        <p>
          The user agrees to indemnify and hold harmless Mammoth Play Parties
          from any claims, losses, damages, or liabilities, including legal fees
          and expenses, arising out of (a) your use or misuse of the Mammoth
          Play Service, (b) any violation by you of the Mammoth Play Policy, or
          (c) any breach of the representations, warranties, and covenants made
          by you herein.
        </p>
        <p>
          Mammoth Play may assume the defense of any matter for which you are
          required to indemnify Mammoth Play by sending notice of such an
          intention to you. The user shall use its best efforts to cooperate
          with Mammoth Play defense of these claims.
        </p>
        <p>
          <strong>9. General Provisions</strong>
        </p>
        <p>
          <strong>9.1. Governing Law</strong>
        </p>
        <p>
          The user agrees that this Agreement shall be deemed to have been made
          and executed in the laws of the Republic of Korea and any Dispute
          arising hereunder shall be resolved in accordance the laws of the
          Republic of Korea.
        </p>
        <p>
          <strong>9.2. Dispute Resolution</strong>
        </p>
        <p>
          If you have any claim arising out of this Agreement against us, we
          strongly encourage you to contact our customer support team to seek a
          resolution. If both parties fail to reach a settlement within 30
          calendar days after the first contact occurs, either party may refer
          such a dispute to the Korean Commercial Arbitration Board in Seoul in
          accordance with the Arbitration Rules of the Korean Commercial
          Arbitration Board. The arbitration is to be conducted before a single
          arbitrator whom the parties jointly select. If the parties are unable
          to agree upon the arbitrator, either party may request the Korean
          Arbitration Association to select the arbitrator.
        </p>
        <p>
          <strong>9.3. Waiver</strong>
        </p>
        <p>
          The waiver by either party of a breach of or a default under any
          provision of this Agreement, shall be in writing and shall not be
          construed as a waiver of any subsequent breach of or default under the
          same or any other provision of this Agreement, nor shall any delay or
          omission on the part of either party to exercise or avail itself of
          any right or remedy that it has or may have hereunder operate as a
          waiver of any right or remedy.
        </p>
        <p>
          <strong>9.4. Force Majeure</strong>
        </p>
        <p>
          Neither party shall be responsible or have any liability for any delay
          or failure to perform to the extent required due to unforeseen
          circumstances or causes beyond its reasonable control, including,
          without limitation, acts of God, earthquake, fire, flood, embargoes,
          labor disputes and strikes, riots, war, acts of a public enemy, error
          in the coding of electronic files, Internet or other network
          &quot;brownouts&quot; or failures, power failures, novelty of product
          manufacture or other unanticipated product development problems, and
          acts of civil and military authorities.
        </p>
        <p>
          <strong>10. Definitions</strong>
        </p>
        <p>
          10.1. Guest account: an account that enables temporary use of the
          service by downloading the application from an app store operated by a
          platform provider or social media provider.
        </p>
        <p>
          10.2. Play account: an account with a platform provider used to
          register for that platform provider&#39;s services.
        </p>
        <p>
          10.3. Social media account: an account used by the user on a social
          media service such as Facebook, etc.
        </p>
        <p>
          10.4. Single sign-on (SSO): a guest account connected to a play
          account or social media account.
        </p>
        <p>10.5. Service: the game service provided by Mammoth Play.</p>
        <p>
          10.6. Application (app): the entire program enabling use of the
          service provided by Mammoth Play.
        </p>
        <p>
          10.7. App store provider: a mobile open market provider (Apple,
          Google, etc.) enabling download of the application provided by Mammoth
          Play and in-app purchases.
        </p>
        <p>
          10.8. Platform provider: the provider offering the service in
          partnership with Mammoth Play and all relevant services.
        </p>
        <p>10.9. Content: items, etc. that can be used on the service.</p>
        <p>
          10.10. In-app purchase: the purchase of content in the application.
        </p>
      </TextWrap>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 3rem 0;
  background-color: white;
  color: black;
`;
const TextWrap = styled.div`
  width: 60%;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectWrap = styled.div`
  width: 60%;
  text-align: end;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectBox = styled.select`
  padding: 0.25rem;
  border: 1px solid black;
`;
const SelectLanguage = styled.option``;

export default TermsOfServiceEN;
