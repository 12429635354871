import styled from "styled-components";

const Table = () => {
    return (
        <TableWrap>
            <tbody>
            <tr>
                <TableCell rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>행위유형</TableCell>
                <TableCell rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    제재 대상 행위
                </TableCell>
                <TableCell colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    이용 제한 기간
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>1차</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>2차</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>3차</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>4차</TableCell>
            </tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
                    비신사적 행위
                </TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    악의적인 의도를 가지고 다른 이용자의 게임진행을 반복적으로 방해하는 행위
                </TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>3일</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>7일</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>15일</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
                    영구
                    <br />
                    제한
                </TableCell>
            </tr>

            <tr>
                <TableCell rowSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    게임 운영을
                    <br />
                    방해하는 행위
                </TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    프로필 사진,닉네임 등을 이용하여 직∙간접적으로 홍보하는 행위
                </TableCell>
                <TableCell rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    7일
                </TableCell>
                <TableCell rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    15일
                </TableCell>
                <TableCell colSpan="2" rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    영구제한
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    동일한 문의, 신고 또는 구매취소 등을 반복하여 <br /> 정상적인 서비스 운영을 방해하는 행위
                </TableCell>
            </tr>

            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    비정상적인 방법으로 게임을 진행하거나, 다른 이용자가 비정상적인 방법으로 게임 진행을 통해 획득한 게임머니를 받거나 제공하는 행위
                </TableCell>
                <TableCell rowSpan="9" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    15일
                </TableCell>
                <TableCell colSpan="7" rowSpan="9" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    영구제한
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    허위사실을 유포하거나 공지되지 않은 비밀정보를 유포하여&nbsp; 부당한 이득을 얻거나
                    <br />
                    타인에게 혼란/피해를 야기시키는 행위
                </TableCell>
            </tr>

            <tr>
                <TableCell rowSpan="3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    사기행위 및 <br />
                    명의도용
                </TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    다른 이용자를 속여 부당하게 게임머니 등 콘텐츠를 취득하는 행위
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    회사의 직원이나 운영자를 가장하거나 사칭 또는 타인의 명의를 도용하는 행위
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    타인의 개인정보를 도용하는 행위 또는 허위신고를 하는 행위
                </TableCell>
            </tr>

            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
                    시스템 오류 또는 <br /> 버그의 악용
                </TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    애플리케이션의 버그, 시스템 오류 등을 이용하여 <br /> 게임을 하거나, 한 것으로 추정되는 행위
                </TableCell>
            </tr>

            <tr>
                <TableCell rowSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    현금거래 등<br /> 콘텐츠 매매 또는
                    <br /> 매매 유도 행위
                </TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    이용자 계정 또는 게임머니 등 콘텐츠를 현금이나 현물 또는 <br /> 다른 게임의 콘텐츠 등과 교환∙거래 등을
                    조장∙시도∙알선하는 행위
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    이용자의 프로필 사진, 닉네임 등을 이용하여 게임머니 등 <br /> 콘텐츠의 거래를 홍보∙조장∙유도∙시도∙알선하는
                    행위
                </TableCell>
            </tr>

            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    이용자 계정 또는 게임머니 등 콘텐츠를 각종 상품권과 교환하거나 <br /> 교환을 시도∙알선∙매입하는 행위
                    <br />
                </TableCell>
            </tr>

            <tr>
                <TableCell style={{ verticalAlign: "middle" }}>
                    게임 이용과정에서 프로그램을 이용하여 게임을 하거나, <br />
                    게임머니 등을 생성∙획득∙판매∙교환∙양도 등을 하거나 시도하는 행위
                    <br />
                </TableCell>
                <TableCell colSpan="4" rowSpan="6" style={{ textAlign: "center", verticalAlign: "middle" }}>
                    영구제한
                </TableCell>
            </tr>

            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>결제 취소 또는 환불 악용</TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    In-app결제 취소(환불) 절차를 의도적으로 악용하여 결제 취소 내지 환불을 반복적으로 하는 행위
                </TableCell>
            </tr>
            <tr></tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>해킹 또는 계정도용</TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    다른 이용자의 계정을 도용 또는 부정하게 사용하거나, <br /> 타인의 결제수단을 도용하여 콘텐츠를 구매하는 행위
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>불법 프로그램의 사용 및 유포</TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    정당한 권한없이 애플리케이션을 조작 또는 비정상적으로 사용하거나 이를 다른 이용자에게
                    <br />
                    전송 또는 유포하는 행위
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>범죄행위</TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    게임 자체 또는 게임 이용과정에서 법률에 위배되는 범죄행위를 하거나 하려고 하는 경우
                </TableCell>
            </tr>
            <tr>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>고객센터 악용</TableCell>
                <TableCell style={{ verticalAlign: "middle" }}>
                    상담 시 욕설 및 폭언을 반복하는 행위
                    <br />
                    인격 모독, 성희롱, 협박 및 위협, 무리한 요구를 하는 행위
                    <br />
                    동일 내용 반복 또는 허위 문의로 업무를 방해하는 행위
                </TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
                    경고 or <br />
                    상담중단
                </TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>3일</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>15일</TableCell>
                <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>영구 제한</TableCell>
            </tr>
            </tbody>
        </TableWrap>
    );
};

const TableWrap = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`;

const TableCell = styled.td`
  text-align:left;
  vertical-align: middle;
  border: 1px solid black;
  padding: 8px;
    line-height: 1.2rem;
`;
export default Table;
