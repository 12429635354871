import { React, useEffect } from "react";
import styled from "styled-components";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SectionTeam = () => {
  useEffect(() => {
    const ani1 = gsap.timeline();
    ani1.from(".logo", { opacity: 0.2 }, { opacity: 1 });

    ScrollTrigger.create({
      animation: ani1,
      trigger: "#pageTrig",
      start: "top ",
      end: "+=20%",
      scrub: true,
      pin: true,
      anticipatePin: 1,
    });
  }, []);

  return (
    <ColorWrapper id="pageTrig">
      <PageWrapper>
        <ContentBox>
          <LeftArea>
            <PageTitle>About Our Team</PageTitle>
            <PageContent>
              Talents from famous Korean
              <br />
              game companies have gathered <br />
              at Mammoth Play to make <br />
              better games. We promise to do <br /> our best to make fun games.
            </PageContent>
          </LeftArea>
          <RightArea className="logo">
            <MMTLogo src="/assets/images/new_mmthplay_logo.png" alt="team-logo" />
          </RightArea>
        </ContentBox>
        <MobileWrapper>
          <MobileTitle>About Our Team</MobileTitle>
          <MobileMMTLogo className="logo" src="/assets/images/new_mmthplay_logo.png" alt="team-logo" />
          <MobilePageContent>
            Talents from famous Korean
            <br />
            game companies have gathered <br />
            at Mammoth Play to make <br />
            better games. We promise to do <br /> our best to make fun games.
          </MobilePageContent>
        </MobileWrapper>
      </PageWrapper>
    </ColorWrapper>
  );
};

const ColorWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #1c1c1c;
`;
const PageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1c1c1c;
  border-bottom-left-radius: 4%;
  border-bottom-right-radius: 4%;
`;
const ContentBox = styled.div`
  padding-top: 20rem;
  display: flex;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;
const LeftArea = styled.div`
  width: 50%;
  padding-left: 12rem;
  @media screen and (max-width: 1500px) {
    padding-left: 8rem;
  }
  @media screen and (max-width: 1080px) {
    padding-left: 4rem;
  }
`;
const RightArea = styled.div`
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding-right: 8rem;
  @media screen and (max-width: 1500px) {
    padding-right: 2rem;
  }
`;
const PageTitle = styled.p`
  font-family: "Pretendard";
  padding-bottom: 2rem;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: 1500px) {
    font-size: 45px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 34px;
  }
`;
const PageContent = styled.p`
  font-family: "Pretendard";
  font-size: 30px;
  font-weight: 42px;
  line-height: 42px;
  color: white;
  padding-bottom: 5rem;
  @media screen and (max-width: 1080px) {
    font-size: 26px;
    font-weight: 500;
    line-height: 42px;
  }
  @media screen and (max-width: 820px) {
    width: 60%;
    font-size: 18px;
    line-height: 21px;
  }
  @media screen and (max-width: 500px) {
    width: 80%;
    font-size: 1.2rem;
    padding-bottom: 4rem;
  }
`;
const MMTLogo = styled.img`
  width: 80%;
  padding-left: 2rem;
`;
/* mobile css */
const MobileWrapper = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 820px) {
    position: relative;
    padding-top: 16rem;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  @media screen and (max-width: 380px) {
    padding-top: 4rem;
  }
`;
const MobileTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  font-family: "Pretendard";
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding-bottom: 0rem;
  }
`;
const MobileMMTLogo = styled.img`
  @media screen and (max-width: 820px) {
    width: 30%;
    padding: 3rem 0;
  }
  @media screen and (max-width: 500px) {
    width: 40%;
    padding: 3rem 0;
  }
`;
const MobilePageContent = styled.p`
  font-family: "Pretendard";
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 5rem;
  color: #fff;
  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 26px;
    padding-bottom: 4rem;
  }
`;
export default SectionTeam;
