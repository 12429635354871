import TableJP from "./TableJP";
import styled from "styled-components";

const ServicePolicy = () => {
  return (
    <Wrapper>
      <SelectWrap>
        <SelectBox
          onChange={(e) => {
            let lang = e.target.value;
            window.location.replace(`https://dosasurvivor.com/service/${lang}`);
          }}
        >
          <SelectLanguage value={"kr"}>한국어</SelectLanguage>
          <SelectLanguage value={"en"}>English</SelectLanguage>
          <SelectLanguage value={"jp"} selected={true}>
            日本語
          </SelectLanguage>
        </SelectBox>
      </SelectWrap>
      <TextWrap>
        <h1 id="-">
          <strong>サービス運営ポリシー</strong>
        </h1>
        <br></br>
        <p>本サービス運営ポリシーは2024年5月9日から有効となります。</p>
        <br></br>
        <h2 id="-1-">
          <strong>1. 共通原則</strong>
        </h2>
        <ul>
          <li>
            お客様のサービス利用制限期間は、各利用者アカウントおよび行動の種類に応じて適用され、禁止されている利用者アカウントで使用されるすべて
            のサービスに利用制限が適用されます。
          </li>
          <br></br>
          <li>
            当社は、サポートセンターやさまざまな組織から受信する公開情報、警察からの捜査情報、または運営者のモニタリングによって情報を把握・確認
            後、禁止行為を行うお客様の利用を制限することができます。
          </li>
          <br></br>
          <li>
            お客様の行為が制裁対象の基準を正確に満たしていない場合でも、サービス利用規約を違反する行為と判断・該当する場合、本サービス運営ポリ
            シーに基づきサービスの利用を制限することができます。
          </li>
          <br></br>
          <li>
            当社は、お客様が制裁対象に該当する行為ないしゲーム内通貨、経験値、ポイント、アイテムなど(以下、「コンテンツ」という。)を生成・獲得する場
            合、サービス利用制限とは別途、生成・獲得したコンテンツを回収したり初期化することができます。また、当社はお客様が制裁対象行為に該当する
            行為ないし方法で生成・獲得したコンテンツを受け取る等、客観的に不法行為に加担が疑われる場合にも、サービス利用制限と別途生成・獲得したコ
            ンテンツを回収したり初期化することができます。
          </li>
          <br></br>
          <li>
            サービス利用の制限は、お客様の行為ないし方法を確認後利用制限が行われるため、行為を行った時機と利用制限時機が相違する場合があり、
            サービス利用制限期間中アカウント削除や脱退を申込む場合、サービス利用制限期間終了までサービス利用が制限または拒否される場合がありま
            す。
          </li>
          <br></br>
          <li>
            客観的な証拠が不十分である場合、あるいは該当するお客様の利用者アカウントが既に制限・削除された場合、利用制限処置は実行されません。
            ただし、調査の結果、より深刻な制限事由に該当する行為や、繰り返し∙常習的な違反行為が追加で確認された場合、すでに制限中でも加重処理さ
            れることがあります。
          </li>
        </ul>
        <br />
        <h2 id="-2-">
          <strong>2. 利用禁止方針</strong>
        </h2>
        <TableJP />
      </TextWrap>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 3rem 0;
  background-color: white;
  color: black;
`;
const TextWrap = styled.div`
  width: 60%;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectWrap = styled.div`
  width: 60%;
  text-align: end;
  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;
const SelectBox = styled.select`
  padding: 0.25rem;
  border: 1px solid black;
`;
const SelectLanguage = styled.option``;

export default ServicePolicy;
