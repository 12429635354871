import styled from "styled-components";

const TableJP = () => {
  return (
    <TableWrap>
      <tbody>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            種類
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            禁止対象となる行為
          </TableCell>
          <TableCell
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            利用制限期間
          </TableCell>
        </tr>
        <tr>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            1次
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            2次
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            3次
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            4次
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="13"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            不適切な行為
          </TableCell>
          <TableCell style={{ verticalAlign: "middle", textAlign: "center" }}>
            悪意を持って繰り返し他のプレイヤーのゲー
            <br />
            ム進行を妨害する行為
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            3日間
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            7日間
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            15日間
          </TableCell>
          <TableCell style={{ textAlign: "center", verticalAlign: "middle" }}>
            永久停止
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="4"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            相手に不快感・嫌悪感・侮辱や羞恥心などを
            <br />
            与えることができる悪口、卑俗語などを直接・
            <br />
            間接的に使用する行為
          </TableCell>
          <TableCell
            rowSpan="10"
            style={{ verticalAlign: "top", textAlign: "center" }}
          >
            1日間
          </TableCell>
          <TableCell
            rowSpan="10"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            3日間
          </TableCell>
          <TableCell
            rowSpan="10"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            7日間
          </TableCell>
          <TableCell
            rowSpan="10"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            永久停止又
            <br />
            24時間
          </TableCell>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            特定の人種・国籍・民族・宗教・性別・人物・障
            <br />
            害などを誹謗したり卑下する行為
          </TableCell>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            選挙運動、宗教活動や宗教団体勧誘、わいせ
            <br />
            つまたは低俗サイトの情報などを直・間接的に 提供する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            同じ内容の会話を意図的・継続的に発する行 為
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            1時間
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            3時間
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            12時間
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            24 時間
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="6"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ゲームプレイ
            <br />に<br />
            干渉する行為
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            直接的または間接的に広告するプロファイル
            <br />
            写真、ニックネームなどを使用する行為
            <br />
            サービス運営を妨げるために、意図的に同じ
            <br />
            問い合わせ、レポート、または購入の取り消し を繰り返す行為
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            7日間
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            15日間
          </TableCell>
          <TableCell
            rowSpan="2"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            永久停止
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            不当な利益を獲得したり、他人に危害や混乱
            <br />
            を引き起こすために、偽情報や機密情報を流 す行為
          </TableCell>
          <TableCell
            rowSpan="18"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            15日間
          </TableCell>
          <TableCell
            rowSpan="18"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            永久停止
          </TableCell>
        </tr>
        <tr />
        <tr />
        <tr />
        <tr>
          <TableCell
            rowSpan="6"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            詐欺行為と
            <br />
            個人情報の
            <br />
            窃盗
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ゲーム内通貨などのコンテンツを不正に獲得
            <br />
            するために他のユーザーを欺く行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            会社の従業員やオペレーターになりすました
            <br />
            り、不正な名前や他人の名前を名乗る行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            個人情報の窃盗
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            システムエ ラーや
            <br />
            不具合の利用
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            アプリケーションの不具合やシステムエラーを
            <br />
            悪用したり、他のプログラムやそのような疑い
            <br />
            のあるものを使用する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="8"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            現金取引を
            <br />
            含むコンテンツ
            <br />の<br />
            取引や取引に
            <br />
            誘う行為
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            現金、記事、その他のゲームコンテンツなどを
            <br />
            使用したユーザーアカウントやゲーム内通貨
            <br />
            などのコンテンツの交換や取引を奨励、試行、
            <br />
            勧誘する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ゲーム内通貨などのコンテンツを宣伝、奨励、
            <br />
            誘導、試行、募集するためにユーザーのプロ
            <br />
            ファイル写真、ニックネームなどを使用する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ユーザーアカウントやゲーム内通貨などのコン
            <br />
            テンツ、あるいはそのようなものの交換の試
            <br />
            行、勧誘、購入のためにギフト券と交換する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            アプリケーションのバ不具合、エラー、不正な
            <br />
            プログラムを使用して生成・獲得したゲーム内
            <br />
            通貨などのコンテンツの交換や販売を試みる行為
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="10"
            colSpan="4"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            永久停止
          </TableCell>
        </tr>
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ハッキングま
            <br />
            たは
            <br />
            アカウントの
            <br />
            乗っ取り
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            他ユーザーのアカウントの窃盗あるいは不正
            <br />
            な使用、または盗した支払い方法によるコンテ
            <br />
            ンツを購入する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            不正なプログ
            <br />
            ラム
            <br />
            の使用または
            <br />
            配布
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            アプリケーションの不正操作および不正な使
            <br />
            用、あるいはそのような方法の他のユーザー
            <br />
            へ送信または配布する行為
          </TableCell>
        </tr>
        <tr />
        <tr>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            違法行為
          </TableCell>
          <TableCell
            rowSpan="2"
            style={{ textAlign: "center", verticalAlign: "top" }}
          >
            ゲーム自体またはゲーム中に関連法規に違
            <br />
            反して犯罪行為または犯罪行為を試みる行為
          </TableCell>
        </tr>
      </tbody>
    </TableWrap>
  );
};

const TableWrap = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
`;

const TableCell = styled.td`
  text-align: left;
  vertical-align: middle;
  border: 1px solid black;
  padding: 8px;
  line-height: 1.2rem;
`;
export default TableJP;
