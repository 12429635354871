import styled from "styled-components";
import "../styles/term.css"

const PrivacyPolicy = () => {
    return (
        <Wrapper>
            <SelectWrap>
                <SelectBox onChange={(e)=>{
                    let lang = e.target.value
                    window.location.replace(`https://dosasurvivor.com/privacy/${lang}`)
                }}>
                    <SelectLanguage value={"kr"}>한국어</SelectLanguage>
                    <SelectLanguage value={"en"}>English</SelectLanguage>
                    <SelectLanguage value={"jp"}>日本語</SelectLanguage>
                </SelectBox>
            </SelectWrap>
            <TextWrap>
                    <h1 id="-2024-04-19-"><strong>개인정보처리방침(2024.04.19)</strong></h1>
                    <p><strong>맘모스플레이 주식회사(이하 “회사”라 함)</strong>는 이용자들의 개인정보를 매우 중요시하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
                        정보보호
                        등에 관한
                        법률 등 정보통신서비스 제공자가 준수하여야 할 관련 법규상의 개인정보보호규정 및 방송통신위원회가 제정한 개인정보보호지침을 준수하고 있습니다.</p>
                    <p>회사는 개인정보처리방침을 통해 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
                    <p>회사는 개인정보처리방침을 서비스 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</p>
                    <p>회사의 개인정보처리방침은 정부의 법률 및 지침 변경이나 회사 내부 방침 변경 등으로 인하여 변경될 수 있으며, 개인정보처리방침의 지속적인 개선을 위하여 필요한 절차를 정하고
                        있습니다.</p>
                    <p>개인정보처리방침을 개정하는 경우 그 변경사항을 개정 최소 7일 전 서비스 내의 별도의 방법으로 게시하여 이용자들이 개정된 사항을 쉽게 알아볼 수 있도록 하고 있습니다.</p>
                    <p>이용자들께서는 서비스 이용 전에 수시로 확인하시기 바랍니다.</p>
                    <p><strong>1. 개인정보 수집에 대한 동의</strong></p>
                    <p>회사는 이용자들이 개인정보처리 방침, 이용약관, 기타 회사에서 제공하는 서비스 정책 내용에 대하여 &lt;동의합니다&gt; 선택하였을 경우 개인정보 수집에 대해 동의한 것으로
                        간주합니다.</p>
                    <p><strong>2. 수집하는 개인정보</strong></p>
                    <p>회사는 아래와 같은 개인정보를 수집합니다.</p>
                    <p>모바일 플랫폼 회원가입 [필수]</p>
                    <ul>
                        <li>회사는 이용자가 플랫폼사업자(예 : 구글, 애플)의 서비스에서 사용하는 이용자의 닉네임(이름), 회원번호, 친구목록, 프로필 사진을 플랫폼사업자(예 : 구글,
                            애플)로부터
                            제공
                            받으며, 플랫폼사업자는 이러한 정보를 이용자가 서비스를 이용하는 시점에 정보통신망법에 따른 개인정보 제공 동의를 받아 회사에게 제공합니다.
                        </li>
                    </ul>
                    <p>서비스 이용과정 자동 수집 항목 [필수]</p>
                    <ul>
                        <li>회사는 플랫폼사업자로부터 제공받는 개인정보외에 서비스를 제공하는 과정에서 혹은 이용자가 서비스를 이용하는 과정에서 서비스 이용기록, 접속로그, 불량이용기록, 통신사
                            정보,
                            OS정보
                            및 기기정보, 광고식별자, 언어 정보, 결제기록을 수집합니다.
                        </li>
                    </ul>
                    <p>애플리케이션 설치 여부 확인 [필수]</p>
                    <ul>
                        <li>서비스 이용과정에서 서비스 애플리케이션간의 연계를 위해 서비스 애플리케이션 및 플랫폼사업자의 애플리케이션(예: Chrome, kakaotalk 등) 설치 여부를
                            확인할 수
                            있는
                            정보가 수집될 수 있습니다. 이 경우 애플리케이션의 설치 여부만을 확인할 수 있는 정보만 수집하며, 다른 정보는 수집하지 않습니다.
                        </li>
                    </ul>
                    <p>이벤트 응모 [선택]</p>
                    <ul>
                        <li>이용자가 서비스에서 진행하는 이벤트에 응모하거나 경품을 신청하는 경우 혹은 서비스 이용 문의 과정에서 추가 정보가 수집될 수 있습니다. 이 경우 위메이드플레이는
                            수집하는
                            개인정보,
                            이용목적, 보관기간 등을 이용자에게 안내하고 별도 동의를 받아 수집합니다.
                        </li>
                    </ul>
                    <p>회사는 아래와 같은 방법으로 개인정보를 수집합니다.</p>
                    <ul>
                        <li>이용자의 개인정보를 <strong>서비스 애플리케이션, 서면, 팩스, 고객센터 상담(전화 또는 이메일), 이벤트 응모, 제휴사로부터의 제공, 생성정보 수집 툴을 통해
                            수집</strong>합니다.
                        </li>
                    </ul>
                    <p><strong>3. 개인정보의 수집목적 및 이용목적</strong></p>
                    <p>회사는 수집한 개인정보를 아래와 같이 이용합니다.</p>
                    <ul>
                        <li>서비스 제공에 필요한 유료콘텐츠(아이템) 구매, 결제, 게임순위보기, 다른 이용자와 게임하기, 알림 서비스 제공 등을 위해 개인정보를 이용합니다.</li>
                        <li>그리고, 가입 의사 또는 탈퇴 의사 확인, 서비스 이용에 따른 이용자 확인 및 식별, 약관 또는 이용정책을 위반하는 이용자의 이용제한조치 등 서비스 운영에 지장을
                            주는 행위
                            방지
                            및 부정이용방지, 분쟁조정을 위한 기록보존, 민원처리, 공지사항 전달 등을 위해 개인정보를 이용합니다.
                        </li>
                        <li>또한, 서비스 이용기록 분석 및 통계와 그에 따른 맞춤형 서비스(광고 포함) 제공, 인구통계학적 특성에 따른 서비스 제공 및 맞춤형 광고 게재, 서비스 개발/제공
                            또는
                            업데이트,
                            이벤트 또는 광고성 정보 제공 등 위해 개인정보를 이용합니다.
                        </li>
                    </ul>
                    <p><strong>4. 수집하는 개인정보의 보유 및 이용기간</strong></p>
                    <p>회사가 제공하는 서비스를 이용자가 이용하는 동안 이용자의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다.</p>
                    <p>회사는 개인정보의 수집 및 이용목적이 달성되면 수집한 개인정보를 지체 없이 파기합니다.</p>
                    <p>다만, 회사가 제공하는 서비스를 이용자가 이용을 중단한 뒤에도 아래와 같은 부득이한 사유로 개인정보를 보관하며, 보존기한이 경과된 경우에는 재생이 불가능한 방법으로
                        삭제합니다.</p>
                    <p><strong>– 서비스 이용에 관한 기록 :</strong> 3개월</p>
                    <p>단, 전자상거래 등에서의 소비자보호에 관한 법률 등의 규정에 근거하여 아래와 같이 일정한 기간 동안 개인정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
                        목적으로만
                        이용합니다.</p>
                    <p><strong>– 계약 또는 청약철회 등에 관한 기록 :</strong> 5년</p>
                    <p><strong>– 대금결제 및 재화 등의 공급에 관한 기록 :</strong> 5년</p>
                    <p><strong>– 소비자의 불만 또는 분쟁처리에 관한 기록</strong> : 3년</p>
                    <p><strong>5. 개인정보의 파기절차 및 방법</strong></p>
                    <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
                    <p><strong>– 파기절차</strong></p>
                    <p>회사는원칙적으로 이용자가 서비스에서 탈퇴하는 경우 이용자의 개인정보를 지체없이 파기합니다. 다만, 이용자에게 개인정보의 보관기간에 대해 별도 동의를 받은 경우 또는 관련
                        법률에서
                        일정
                        기간동안 보관의무를 명시하고 있는 경우에는 해당 기간동안 보관 후 지체없이 파기합니다. 또한, 개인정보보호법에 따라 1년간 서비스를 이용하지 않은 이용자의 개인정보는 파기
                        및 분리
                        하며,
                        분리 보관된 개인정보는 5년간 보관 후 지체없이 파기합니다.</p>
                    <p><strong>– 파기방법</strong></p>
                    <p>전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
                    <p><strong>6. 수집한 개인정보의 공유 및 제공</strong></p>
                    <p>회사는 이용자들의 개인정보를 “3. 개인정보의 수집목적 및 이용목적”에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 범위를 초과하여 이용하거나 원칙적으로
                        이용자의
                        개인정보를
                        외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
                    <p><strong>– 이용자들이 사전에 공개에 동의한 경우</strong></p>
                    <p><strong>– 서비스 제공에 따른 요금정산을 위하여 필요한 경우</strong></p>
                    <p><strong>– 기타 법에 의해 요구된다고 선의로 판단되는 경우</strong>(ex. 관련법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)</p>
                    <p><strong>7. 개인정보의 위탁처리</strong></p>
                    <p>회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영할 수 있습니다. 관계법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 규정하고
                        있습니다.</p>
                    <p>고객 지원 업무, 고객상담, 불만처리 등 민원처리, SMS 발송, DM 발송, 이벤트 진행 및 상품 배송 서비스 이용정지 또는 위탁계약 종료 시까지</p>
                    <p><strong>8. 개인정보관련 기술적-관리적 대책</strong></p>
                    <p>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조, 훼손되지 않고 안전성을 확보하기 위하여 다음과 같은 기술적 및 관리적 대책을 강구하고
                        있습니다.</p>
                    <p>회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</p>
                    <p>개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호 알고리즘 등을
                        통하여
                        네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</p>
                    <p>또한 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</p>
                    <p>회사는 이용자의 개인정보 관련 처리를 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보
                        처리방침의
                        준수를
                        항상 강조하고 있습니다.</p>
                    <p>개인정보 관련 처리를 담당하고 있는 담당자는 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자, 기타업무상 개인정보의 처리가 불가피한자로 한정합니다.</p>
                    <p><strong>9. 이용자 및 법정대리인의 권리와 그 행사방법</strong></p>
                    <p>이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 수정하거나 삭제를 요청 할 수 있습니다.</p>
                    <p>만일, 이용자 및 법정대리인은 회사가 개인정보를 처리하는 것에 동의하지 않는 경우에는 동의를 거부하거나 삭제를 요청할 수 있습니다. 이 경우 서비스의 일부 또는 전부 이용이
                        어려울
                        수
                        있습니다.</p>
                    <p>개인정보를 수정하거나 삭제를 원하는 경우 회사의 개인정보 보호책임자에게 서면, 고객센터 등으로 연락하시면 지체 없이 조치하겠습니다.</p>
                    <p>회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보는
                        “5. 개인정보의 파기절차 및 방법”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
                    <p><strong>10. 개인정보 관리 책임자의 소속-성명 및 연락처</strong></p>
                    <p>회사는 이용자가 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다.</p>
                    <p>개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하는 사고가 발생할 경우 개인정보 보호책임자가 책임을 집니다.</p>
                    <p>이용자 개인정보와 관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게 있습니다.</p>
                    <p>회사는 비밀번호에 대해 어떠한 방법으로도 이용자에게 직접적으로 질문하는 경우가 절대로 없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다.</p>
                    <p>회사는 개인정보에 대한 의견 수렴 및 불만 처리를 담당으로 하는 개인정보 보호책임자를 지정하고 있고, 연락처는 아래와 같습니다.</p>
                    <p><strong>개인정보 보호책임자</strong></p>
                    <p><strong>성 명: 박진</strong></p>
                    <p><strong>직위: CEO</strong></p>
                    <p><strong>연락처 : <a
                        href="mailto:help@mammothplay.io"><LinkText>help@mammothplay.io</LinkText></a></strong></p>
                    <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
                    <p>1) 개인정보침해신고센터 (https://privacy.kisa.or.kr/ 국번없이 118)</p>
                    <p>2) 대검찰청 사이버수사과 (http://www.spo.go.kr/ 국번없이 1301)</p>
                    <p>3) 경찰청 사이버안전국 (http://www.cyber.go.kr/ 국번없이 182)</p>
                    <p>개인정보처리방침 시행 일자 : 2024년 4월 19일</p>
            </TextWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
    background-color: white;
    color: black;
`
const TextWrap = styled.div`
    width: 60%;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const LinkText = styled.span`
    color: blue;
    font-weight: 900;
    text-decoration: underline;
`
const SelectWrap = styled.div`
    width: 60%;
    text-align: end;
    @media screen and (max-width: 1240px) {
        width: 90%;
    }
`
const SelectBox = styled.select`
    padding: 0.25rem;
    border: 1px solid black;
`
const SelectLanguage = styled.option``

export default PrivacyPolicy